import census  from 'citysdk';

function locationsDomainsRightPanel() {

  return {
    restrict: 'E',
    scope: {},
    templateUrl: 'home/locations/locationsDomainsRightPanel/tpl.html',
    bindToController: {},
    controller: controller,
    controllerAs: 'vm'
  };

  function controller($stateParams, LocationsFactory, ENV) {
    'ngInject';

    const vm = this;

    vm.$onInit = $onInit;

    function $onInit() {

      vm.demographics = {};
      vm.location = {};

      vm.mdTabsIndex = {
        providers: 2,
        demographics: 1,
        metrics: 0
      };

      // Take the state params and display metrics, demographics, or providers
      vm.currentView = vm.mdTabsIndex[$stateParams.tab] || 0;

      LocationsFactory.get({
          city: $stateParams.city,
          state: $stateParams.state
        })
        .then((res) => {

          vm.location = res.locations.length && res.locations[0];

          vm.location.center = vm.location && {
            lat: vm.location.lat,
            lng: vm.location.lon,
            zoom: 12
          };

        });

    }

  }

}

export default locationsDomainsRightPanel;
