import angular from 'angular';
import '@uirouter/angularjs';

import mapsRoutes from './routes';
import mapContent from './mapContent/directive';
import domainsMap from './domainsMap/directive';

const mapsModule = angular.module('ufn.lost.home.map', [
    'ui.router'
]);

mapsModule.config(mapsRoutes);

mapsModule.directive('mapContent', mapContent);
mapsModule.directive('domainsMap', domainsMap);

export default mapsModule;
