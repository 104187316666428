import moment from 'moment';

function locationsProviders() {

  return {
    restrict: 'E',
    scope: {},
    templateUrl: 'home/locations/locationsProviders/tpl.html',
    bindToController: {
      location: '='
    },
    controller: controller,
    controllerAs: 'vm'
  };

  function controller($filter, $stateParams, $scope, $log, LocationsFactory, ProvidersFactory) {
    'ngInject';

    const vm = this;

    vm.$onInit = $onInit;
    vm.getProviders = getProviders;
    vm.getProviderMarketShare = getProviderMarketShare;
    vm.fillInRows = fillInRows;
    vm.pushRows = pushRows;

    function $onInit() {

      vm.selected = [];
      vm.graphRange = '';
      vm.providers = [];
      vm.query = {
        order: 'name'
      };

      vm.headline = $stateParams.locationId ?
        $stateParams.city + ', ' + $stateParams.state :
        'All Locations';

      vm.getProviders();

    }

    function getProviders() {

      var locationId = $stateParams.locationId || (vm.location && vm.location.id) || '';

      LocationsFactory.getProviders(locationId)
        .then((res) => {

          vm.providers = res.map((provider) => {

            provider.metrics.map((metric) => {

              Object.assign(metric, {
                domains: metric.domains || 0,
                num_shops: metric.num_shops || 0,
                traffic: metric.traffic || 0
              })

              return metric;

            });

            return provider;

          });

          vm.providerMarketShare = vm.getProviderMarketShare();

        });

    }

    function getProviderMarketShare() {

      var textStyle = {
        color: '#9e9e9e',
        fontSize: '10px'
      };

      var chartOpts = {
        legend: 'none',
        // isStacked: true,
        lineWidth: 2,
        displayExactValues: true,
        height: 300,
        chartArea: {
          width: 460,
          height: 220
        },
        vAxis: {
          title: 'Traffic',
          textStyle: textStyle,
          gridlines: {
            // count: 6
          }
        },
        hAxis: {
          textStyle: textStyle
        },
        textStyle: {
          color: '#666',
          fontName: 'Roboto,"Helvetica Neue",sans-serif',
        },
        colors: []
      };

      // Base chart obj
      var chart = {
        type: 'AreaChart',
        data: {
          cols: [{
            id: 'date-id',
            label: 'Date',
            type: 'string'
          }],
          rows: []
        },
        options: chartOpts
      };

      // Distill to just names
      var selectedProviders = vm.selected.map((provider) => {
        return provider.provider
      });

      // Filter out providers not in selected list
      var graphProviders = selectedProviders.length ?
        vm.providers.filter((provider) =>
          selectedProviders.indexOf(provider.provider) >= 0) :
        vm.providers;

      // Fill in the rows
      var dataRows = vm.fillInRows(graphProviders, chart);

      // Push filled in rows into the chart obj
      vm.pushRows(dataRows, chart);

      return chart;

    }

    function fillInRows(graphProviders, chart) {

      var formattedDate,
        datesListMetrics = {},
        providerColorMap = {
          ufn: '#7EA43D',
          ftd: '#FFCB08',
          teleflora: '#F9145F',
          bloomnation: '#33888D',
          fsn: '#FF9D11',
          floranext: '#E577A4',
          bloomnet: '#532480',
          media99: '#F26424',
          websystems: '#D7EC95',
          custom: '#ABABAB',
          domainsforsale: '#06FF00',
          epicflowers: '#11E4E6',
          pfs: '#FF0018',
          yahoostore: '#8A2A8F',
          flowerslocal: '#4D7B19',
          rfk: '#1F4083',
          lafs: '#E91FDC',
          squarespace: '#000000',
          vistaprint: '#2BA8DF',
          bigcommerce: '#7FD4E5',
          volusion: '#005581',
          shopify: '#B5E55A',
          floristboard: '#1E380D',
          webshop101: '#F4FF81',
          canafloral: '#FF8A80',
          ypf: '#FFCDD2',
          unknown: '#fefefe'
        };

      // Fill in the rows
      graphProviders.forEach((provider, i) => {

        // Assign corresponding colors per provider
        chart.options.colors.push(providerColorMap[provider.provider] || '#' + Math.random().toString(16).slice(2, 8).toUpperCase());

        // Push provider label obj to column
        chart.data.cols.push({
          id: provider.provider + '-id',
          label: $filter('capitalize')(provider.provider) + ' traffic: ',
          type: 'number'
        });

        // take all metrics for each date, put into array
        provider.metrics.forEach((metric) => {

          // Create month/year label, group all matching labels together
          formattedDate = moment(metric.created).format("MMM 'YY");

          // If this month doesn't exist yet, create an array for it
          datesListMetrics[formattedDate] = datesListMetrics[formattedDate] || [];

          // Push data to array
          datesListMetrics[formattedDate].push({
            v: metric.traffic || 0
          });

        });

      });

      return datesListMetrics;

    }

    function pushRows(datesList, chart) {

      var dateMetrics;

      $log.debug('datesList', datesList.length);

      // Push each month into rows
      angular.forEach(datesList, (val, key) => {

        $log.debug('val', val, 'key', key);

        // Create array with date first,
        // followed by providers' metric for that month
        dateMetrics = [{
          v: key
        }];

        // Push each metric attached to the date
        val.forEach((metricObj) => {

          dateMetrics.push(metricObj);

        });

        chart.data.rows.push({ c: dateMetrics });

      });

      chart.data.rows.reverse();

    }

    $scope.$watch('vm.selected', (newVal, oldVal) => {

      if (!vm.providers.length || angular.equals(newVal, oldVal)) {
        return
      };

      vm.providerMarketShare = vm.getProviderMarketShare();

    }, true);

  }

}

export default locationsProviders;
