module.exports={
  "domains": [{
    "id":        1,
    "name":      "flowerworksflorist.com",
    "shop_name": "Flowerworks Florist",
    "owner":     "Flowerworks Owner",
    "email":     "owner@flowerworksflorist.com",
    "phone1":    "8455555555",
    "phone2":    "8455555555",
    "provider":  "ftd",
    "location":  {
      "street1": "3380 Fort Hamilton Pkwy",
      "street2": "",
      "lat":     41.532467,
      "lng":     -73.863831,
      "city":    "Brooklyn",
      "state":   "NY",
      "postal":  "11218",
      "country": "US",
    },
    "alerts":    [
      "contacted"
    ],
    "score":     10,
    "traffic":   150,
    "ad_spend":  280,
    "ufn_score": "A",
    "google_rank": 1
  },
  {
    "id":        2,
    "name":      "avenuejfloral.com",
    "shop_name": "Avenue J Floral",
    "owner":     "Avenue J Floral",
    "email":     "info@avenuejfloral.com",
    "phone1":    "8455555555",
    "phone2":    "8455555555",
    "provider":  "ufn",
    "location":  {
      "street1": "1502 Avenue J",
      "street2": "",
      "city":    "Brooklyn",
      "state":   "NY",
      "postal":  "12345",
      "country": "US",
      "lat":     41.532467,
      "lng":     -73.863831
    },
    "alerts":    [
      "contacted"
    ],
    "score":     10,
    "traffic":   200,
    "ad_spend":  200,
    "ufn_score": "A",
    "google_rank": 1
  },
  {
    "id":        3,
    "name":      "shannonflorist.com",
    "shop_name": "David Shannon Florist & Nursery",
    "owner":     "David Shannon",
    "email":     "info@shannonflorist.com",
    "phone1":    "8455555555",
    "phone2":    "8455555555",
    "provider":  "floranext",
    "location":  {
      "street1": "3380 Fort Hamilton Pkwy",
      "street2": "",
      "city":    "Brooklyn",
      "state":   "NY",
      "postal":  "11218",
      "country": "US",
      "lat":     40.6462509,
      "lng":     -73.863831
    },
    "alerts":    [
      "contacted"
    ],
    "score":     10,
    "traffic":   250,
    "ad_spend":  280,
    "ufn_score": "A",
    "google_rank": 1
  },
  {
    "id":        4,
    "name":      "flowerdeliverybrooklyn.com",
    "shop_name": "Flowers Brooklyn",
    "owner":     "Flowers Brooklyn Owner",
    "email":     "info@flowerdeliverybrooklyn.com",
    "phone1":    "8455555555",
    "phone2":    "8455555555",
    "provider":  "ufn",
    "location":  {
      "street1": "2915 West 5th Street",
      "street2": "",
      "city":    "Brooklyn",
      "state":   "NY",
      "postal":  "12345",
      "country": "US",
      "lat":     40.5777685,
      "lng":     -74.0419184
    },
    "alerts":    [
      "contacted"
    ],
    "score":     10,
    "traffic":   250,
    "ad_spend":  200,
    "ufn_score": "A",
    "google_rank": 1
  }]
}