function MapsFactory($http, $q, $log, JavaScriptFactory) {
  'ngInject';

  const gMapsApiReadyDeferred = $q.defer();

  var service = {
    bootstrap: bootstrap,
    gMapsApiReady: gMapsApiReadyDeferred.promise,
    geocodeAddress: geocodeAddress,
    getAddressComponent: getAddressComponent,
    // getDistance: getDistance,
    getLocations: getLocations,
    parseBusinessAddress: parseBusinessAddress,
    parseGeocodeAddress: parseGeocodeAddress
  };

  // Wait for API to be available before instantiating Google Maps JavaScript API classes
  service.gMapsApiReady
    .then(() => {

      // Add all Google Maps JavaScript APIs to service
      Object.assign(
        service, {
          autocompleteService: new google.maps.places.AutocompleteService(),
          distanceMatrixService: new google.maps.DistanceMatrixService(),
          geocoderService: new google.maps.Geocoder(),
          // Places services requires a map node
          placesService: new google.maps.places.PlacesService(document.createElement('div'))
        }
      );

    });

  return service;

  function bootstrap(key) {

    // Google APIs already bootstrapped or we were not given a key
    if (window.google || !key) {

      return;

    }

    JavaScriptFactory.inject(
        'https://maps.google.com/maps/api/js?v=3.27&key=' + key + '&libraries=places'
      )
      // Let the rest of the service know we're ready
      .then(gMapsApiReadyDeferred.resolve);

  }

  // see https://developers.google.com/maps/documentation/javascript/3.exp/reference#GeocoderRequest
  function geocodeAddress(request) {

    var deferred = $q.defer();

    // see https://developers.google.com/maps/documentation/javascript/3.exp/reference#Geocoder
    service.geocoderService.geocode({
        address: request.address,
        location: request.location,
        placeId: request.placeId,
        // Should be a temporary measure to disable the "new" Google Forward Geocoder
        // https://developers.google.com/maps/documentation/geocoding/faq
        // https://developers.google.com/maps/documentation/javascript/3.exp/reference#GeocoderRequest
        newForwardGeocoder: false
      },
      (res, status) => {

        if (status !== 'OK') {

          return deferred.reject(status);

        }

        return deferred.resolve(res);

      }
    );

    return deferred.promise;

  }

  function getAddressComponent(address, componentName) {

    return (
        address.address_components.find(
          (component) => component.types.includes(componentName)
        ) || {}
      )
      .short_name;

  }

  function getLocations(addressString, proximity, address) {

    // Set account info
    var account = address || {};

    var deferred = $q.defer();

    // Many options exist to get list of places,
    // but this fits biasing and type limitation requirements
    service.autocompleteService.getPlacePredictions(
      Object.assign({
          input: addressString
        },
        // Limit by proximity only if we were told to
        proximity && {
          // This may include more than street addresses,
          // like school districts, but this is the most precise type
          // for getPlacePredictions
          types: ['geocode'],
          // Do not give any results outside account country
          componentRestrictions: { country: account.country },
          // Bias results to account location,
          // Must be a LatLng, not LatLngLiteral
          location: (account.lat && account.lon) ? new google.maps.LatLng(account.lat, account.lon) : '',
          // required distance for bias circle
          // 16094m = ~10mi.
          radius: 16094
        }
      ),
      (data) => {

        // No results will be undefined
        deferred.resolve(data || []);

      }
    );

    return deferred.promise;

  }

  function parseBusinessAddress(placeDetails) {

    return parseGeocodeAddress(placeDetails)
      .then((geocodeAddressInfo) => $q.when(
        Object.assign({},
          geocodeAddressInfo, {
            name: placeDetails.name,
            google_place_id: placeDetails.place_id
          }
        )
      ));

  }

  function parseGeocodeAddress(res) {

    var address = {};

    // Ease reference
    // address will be obj array, place details will be object
    res = res[0] || res;

    if (!res) {

      return $q.reject();

    }

    // Lat / Lon Assignment
    address.lat = res.geometry.location.lat();
    address.lon = res.geometry.location.lng();

    // Address Assignment
    address.street_1 = [
        getAddressComponent(res, 'street_number') || '',
        getAddressComponent(res, 'route') || ''
      ]
      // Remove undefined elements
      .filter((e) => e)
      // Join to finally make 'street_number route/street'
      .join(' ');

    address.city = getAddressComponent(
        res,
        'locality'
      )
      // both locality/administrative_area_level_3 represent city
      || getAddressComponent(res, 'locality') || getAddressComponent(res, 'administrative_area_level_3') || getAddressComponent(res, 'sublocality') || '';

    address.postal = getAddressComponent(
      res,
      'postal_code'
    ) || '';

    address.state = getAddressComponent(
      res,
      'administrative_area_level_1'
    ) || '';

    address.country = getAddressComponent(
      res,
      'country'
    ) || '';

    return $q.when(address);

  }

}

export default MapsFactory;
