function domainCompetitors($document, $log) {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: 'home/domains/competitors/tpl.html',
    scope: {},
    bindToController: {},
    controller: controller,
    controllerAs: 'vm'
  };

  function controller(DomainsFactory, $stateParams) {
    'ngInject';

    const vm = this;

    vm.$onInit = $onInit;

    function $onInit() {

      DomainsFactory.getCompetitors($stateParams.domainName)
        .then((res) => {

          vm.competitors = res;

        });

    }


  }

}

export default domainCompetitors;
