module.exports={
    "tags": [{
        "id": 1,
        "name": "floranext",
        "display_name": "Floranext"
    }, {
        "id": 2,
        "name": "brooklyn",
        "display_name": "Brooklyn"
    }, {
        "id": 3,
        "name": "may2015blitz",
        "display_name": "May 2015 Blitz"
    }, {
        "id": 4,
        "name": "lynn",
        "display_name": "Lynn"
    }]
}
