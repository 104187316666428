function domains($q, $state, $stateParams) {
  'ngInject';

  return {
    restrict: 'E',
    scope: {},
    template: '<div id="map" flex></div>',
    bindToController: {
      domains: '=ngModel',
      isLoading: '='
    },
    controller: controller,
    controllerAs: 'vm',
    link: link
  };

  function controller($log, $scope, MapsFactory, DomainsFactory, ENV) {
    'ngInject';

    const vm = this;

    // Methods
    vm.setUpMap = setUpMap;
    vm.createFeature = createFeature;

    function setUpMap(domains) {

      // If map already set up, remove it
      if (angular.isDefined(map)) {
        map.remove();
      }

      var map,
        markerLayer,
        geojson,
        i,
        feature,
        marker,
        bounds,
        markerCluster;

      // If no domains, don't set up map
      // if (!domains.length) {
      //   return;
      // }

      MapsFactory.gMapsApiReady.then(() => {

        map = new google.maps.Map(document.getElementById('map'), {
          zoom: 4,
          mapTypeControl: false,
          streetViewControl: false
        });

        // If no location, use default fitbounds
        // bounds = new google.maps.LatLngBounds();
        // bounds.extend(new google.maps.LatLng(22.917922936146045, -127.265625));
        // bounds.extend(new google.maps.LatLng(50.28933925329178, -64.3359375));
        // map.fitBounds(bounds);

        map.setCenter({
          lat: (Number($stateParams.lat) || 37.142927),
          lng: (Number($stateParams.lng) || -95.745320)
        });

        map.setZoom((Number($stateParams.lat) || 37.142927 && Number($stateParams.lng) || -95.745320) ? 4 : 4);

        let features = domains.length && domains
          // Filter out domains without lat and lon
          .filter((f) => angular.isDefined(f.lat) &&
            f.lat &&
            f.lat !== null &&
            angular.isDefined(f.lon) &&
            f.lon &&
            f.lon !== null)
          // Create feature with domain
          .map((m) => vm.createFeature(m, map));

        geojson = {
          type: 'FeatureCollection',
          features: features
        };

        markerCluster = new MarkerClusterer(map, features, {
          imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
        });

      });

    }

  }

  function createFeature(d, map) {

    if (angular.isUndefined(d.lon) ||
      d.lon === null ||
      angular.isUndefined(d.lat) ||
      d.lat === null
    ) {
      return;
    }

    var providerColorMap = {
      ufn: '#7EA43D',
      ftd: '#FFCB08',
      teleflora: '#F9145F',
      bloomnation: '#33888D',
      fsn: '#FF9D11',
      floranext: '#E577A4',
      bloomnet: '#532480',
      media99: '#F26424',
      websystems: '#D7EC95',
      custom: '#ABABAB',
      domainsforsale: '#06FF00',
      epicflowers: '#11E4E6',
      pfs: '#FF0018',
      yahoostore: '#8A2A8F',
      flowerslocal: '#4D7B19',
      rfk: '#1F4083',
      lafs: '#E91FDC',
      squarespace: '#000000',
      vistaprint: '#2BA8DF',
      bigcommerce: '#7FD4E5',
      volusion: '#005581',
      shopify: '#B5E55A',
      floristboard: '#1E380D',
      webshop101: '#F4FF81',
      canafloral: '#FF8A80',
      ypf: '#FFCDD2',
      unknown: '#FEFEFE'
    };

    var icon = {
      path: 'M5.81,0A5.8,5.8,0,0,0,0,5.79c0,4.35,5.8,10.77,5.8,10.77S11.6,10.15,' +
        '11.6,5.79A5.79,5.79,0,0,0,5.81,0Zm0,7.87A2.07,2.07,0,1,1,7.88,5.79h' +
        '0A2.07,2.07,0,0,1,5.81,7.87Z',
      fillColor: providerColorMap[d.provider] || providerColorMap.unknown,
      fillOpacity: 1,
      strokeWeight: 0
    };

    var marker = new google.maps.Marker({
      position: { lat: d.lat, lng: d.lon },
      map: map,
      icon: icon,
      title: [d.company, d.domain_name].filter((v) => angular.isDefined(v)).join(': ')
    });

    var infowindow = new google.maps.InfoWindow({
      content: d.domain_name
    });

    marker.addListener('click', (e) => {
      infowindow.open(map, marker);
      $state.go(
        ($stateParams.domainName ? '.' : '.details'), {
          domainName: d.domain_name
        }
      );
    });

    return marker;

  }

  function link(scope, elem) {
    'ngInject';

    const vm = scope.vm;

    // Watch for domains to be added before setting up map
    scope.$watch('vm.domains', (newValue, oldValue) => {

      if (!newValue) {
        return;
      }

      vm.setUpMap(newValue);

    });

    // Watch stateparams,
    // if domain id exists, location of state.go needs to change on the fly
    scope.$watchCollection(() => {

      return $state.params;

    }, (newValue, oldValue) => {

      if (!angular.equals(newValue.domainName, oldValue.domainName) &&
        angular.isDefined(newValue.domainName) &&
        vm.domains) {
        return;
      }

      vm.setUpMap(vm.domains);

    }, true);

  }

}

export default domains;
