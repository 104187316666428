function lowercaseStrip() {
    'ngInject';

    return function(input) {

        if (!input) {
            return '';
        }

        return angular.lowercase(input.replace(/ /g, ''));

    };

}

export default lowercaseStrip;
