/**
 * @ngdoc object
 * @name ufn.pos.factory:PersistModelFactory
 *
 * @description helper functions for using persistModel directive
 *
 */

function PersistModelFactory($state, $localStorage) {
  'ngInject';

  var service = {
    deletePersistedModel: deletePersistedModel,
    getLocalStorageKey: getLocalStorageKey,
    getPersistedModel: getPersistedModel,
    setPersistedModel: setPersistedModel
  };

  return service;

  function deletePersistedModel(modelName) {

    delete $localStorage[getLocalStorageKey(modelName)];

  }

  function getLocalStorageKey(modelName) {

    return $state.current.name + '.' + modelName;

  }

  function getPersistedModel(modelName) {

    return $localStorage[getLocalStorageKey(modelName)];

  }

  function setPersistedModel(modelName, modelVal) {

    $localStorage[getLocalStorageKey(modelName)] = modelVal;

  }

}

export default PersistModelFactory;
