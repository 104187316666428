function locationsDomainsMetrics() {

  return {
    restrict: 'E',
    scope: {},
    templateUrl: 'home/locations/locationsDomainsMetrics/tpl.html',
    bindToController: {
      location: '='
    },
    controller: controller,
    controllerAs: 'vm'
  };

  function controller($stateParams, LocationsFactory) {
    'ngInject';

    const vm = this;

    vm.$onInit = $onInit;
    vm.getMetrics = getMetrics;
    vm.updateMetrics = updateMetrics;

    function $onInit() {

      vm.updatingMetrics = false;

      vm.getMetrics();

    }

    function getMetrics() {

      LocationsFactory.getMetrics($stateParams.locationId)
        .then((res) => {

          vm.metrics = res;

        });

    }

    function updateMetrics() {

      vm.updatingMetrics = true;

      LocationsFactory.updateMetrics([$stateParams.locationId])
        .finally(() => {

          vm.updatingMetrics = false;

        });

    }

  }

}

export default locationsDomainsMetrics;
