// import domainsTestData from './domains.json';
// import domainsActivityTestData from './activity/activity.json';
// import domainsCompetitorsTestData from './info/domainCompetitors.json';

function BlacklistFactory($log, $http, $q, ENV) {
  'ngInject';

  var service = {
    getList: getList,
    removeFromBlacklist: removeFromBlacklist
  };

  return service;

  function getList(params) {

    return $http.get(ENV.API_BASE_URL + '/blacklist/', { params: params })
      .then((res) => {

        return $q.when(res.data);

      });

  }

  function removeFromBlacklist(domains) {

    let data = { domains: domains };

    return $http.delete(ENV.API_BASE_URL + '/blacklist/', {
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        data: data
      })
      .then(function (res) {

        return $q.when(res);

      });

  }

}

export default BlacklistFactory;
