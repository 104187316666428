function domainsContent($document, $log, $state, $stateParams, $mdDialog, $q, ModalService, DomainsFactory, SelectedDomainsFactory) {
  'ngInject';

  return {
    restrict: 'E',
    scope: {},
    templateUrl: 'home/domains/domainsContent/tpl.html',
    bindToController: {
      showDistanceFilter: '='
    },
    controller: controller,
    controllerAs: 'vm',
    link: link
  };

  function controller($scope) {
    'ngInject';

    const vm = this;

    vm.$onInit = $onInit;
    vm.getDomains = getDomains;
    vm.getNextPage = getNextPage;
    vm.isChipReadonly = isChipReadonly;
    vm.onExport = onExport;

    function $onInit() {

      vm.page = 1;
      vm.limit = 25;
      vm.disablePaging = false;
      vm.isPageLoading = false;
      vm.domains = [];

      vm.showRowsClass = '';

      vm.$stateParams = $stateParams;
      vm.showMap = vm.$stateParams.content === 'map';

      vm.filters = {
        city: $stateParams.city,
        state: $stateParams.state,
        country: $stateParams.country,
        // Coerce to integers to simplify filters
        traffic: $stateParams.traffic && parseInt($stateParams.traffic),
        distance: $stateParams.distance && parseInt($stateParams.distance),
        text: $stateParams.text,
        provider: $stateParams.provider,
        alerts: $stateParams.alerts,
        contacted: $stateParams.contacted,
        https: $stateParams.https,
        tag: $stateParams.tag,
        status: $stateParams.status,
        duplicates: $stateParams.duplicates,
        clear: $stateParams.clear,
        name: $stateParams.name
      };

      vm.sort = (
          $stateParams.sort && (
            $stateParams.sort_order === 'desc' ? '-' : ''
          ) + $stateParams.sort
        )
        // Use state param defaults to prevent triggering a state change,
        // but provide a default just in case
        || 'id';

      vm.getNextPage();

    }

    function getDomains(filterParams) {

      return DomainsFactory.getList(filterParams);

    }

    function getNextPage() {

      // Paging in progress or end of list
      if (vm.disablePaging || vm.isPageLoading) {

        return;

      }

      vm.disablePaging = true;
      vm.isPageLoading = true;

      var domainsParams = Object.assign({}, vm.showMap ? {
        mapview: true,
        exclude_status: $stateParams.status && $stateParams.status.indexOf('599') !== -1 ? String(null) : 'null,599'
      } : {
        page: vm.page,
        limit: vm.limit,
        sort: $stateParams.sort,
        sort_order: $stateParams.sort_order,
        exclude_status: $stateParams.status && $stateParams.status.indexOf('599') !== -1 ? String(null) : 'null,599'
      });

      angular.extend(domainsParams, vm.filters);

      vm.getDomains(domainsParams)
        .then((res) => {

          vm.domains = DomainsFactory.extendMapView(vm.domains.concat(res.domains));

          vm.page = res._page && res._page.next;

          if (vm.page) {
            vm.disablePaging = false;
          }

        })
        .finally(() => vm.isPageLoading = false);

    }

    function isChipReadonly(chip) {

      return chip && (
        $state.is('home.302s') && (
          chip.type === 'status' && chip.value === '302'
        ) || $state.is('home.locations.list.domains') && (
          chip.type === 'location'
        )
      );

    }

    function onExport(filterParams) {

      var domainFilters = Object.assign(angular.copy(filterParams), {
        page: undefined,
        limit: undefined,
        traffic: $stateParams.traffic,
        exclude_status: $stateParams.status &&
          $stateParams.status.indexOf('599') !== -1 ?
          String(null) : 'null,599'
      });

      return vm.getDomains(domainFilters)
        .then((res) => res.domains);

    }

  }

  function link(scope) {

    const vm = scope.vm;

    scope.$watch('vm.filters', (filters) => {

      // This prevents us from having to explicitly add
      // all non-filter parameters onto the search
      $stateParams.traffic = null;
      $stateParams.distance = null;
      $stateParams.text = null;
      $stateParams.provider = null;
      $stateParams.alerts = null;
      $stateParams.contacted = null;
      $stateParams.https = null;
      $stateParams.tag = null;
      $stateParams.status = null;
      $stateParams.duplicates = null;
      $stateParams.clear = null;
      $stateParams.name = null;
      Object.assign($stateParams, filters);

      $state.go($state.current, $stateParams);

    }, true);

  }
}

export default domainsContent;
