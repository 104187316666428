import locationsTestData from './locations.json';
import demoTestData from './demographics.json';
import locationsMetricsData from './locationsMetrics/metrics.json';
import locationsDomainsMetricsData from './locationsDomainsMetrics/metrics.json';
import locationsProvidersData from './locationsProviders/providers.json';

function LocationsFactory($log, $http, $q, ENV) {
  'ngInject';

  var service = {
    get: get,
    getDemographics: getDemographics,
    getMetrics: getMetrics,
    updateMetrics: updateMetrics,
    getProviders: getProviders
  };

  return service;

  function get(params) {

    return $http.get(ENV.API_BASE_URL + '/locations/', { params: params })
      .then(function (res) {

        return $q.when(res.data);

      })

  }

  function getDemographics(params) {

    return $http.get(ENV.API_BASE_URL + '/locations/demographics', params)
      .then(function (res) {

        return $q.when(res.domains);

      });

  }

  function getProviders(locationId) {

    return $http.get(ENV.API_BASE_URL + '/locations/providers/' + locationId)
      .then(function (res) {

        return $q.when(res.data.locations_providers);

      });

  }

  function getMetrics(locationId) {

    return $http.get(ENV.API_BASE_URL + '/locations/metrics/' + locationId)
      .then(function (res) {

        return res.data.sem_lmetrics && $q.when(res.data.sem_lmetrics[0]);

      });

  }

  function updateMetrics(locationIds) {

    return $http.post(ENV.API_BASE_URL + '/locations/domains/', {
      location_ids: locationIds
    });

  }

}

export default LocationsFactory;
