function listMapToggle() {

  return {
    restrict: 'E',
    scope: {},
    templateUrl: 'home/listMapToggle/tpl.html',
    bindToController: {},
    controller: controller,
    controllerAs: 'vm'
  };

  function controller($state, $stateParams) {
    'ngInject';

    const vm = this;

    vm.$onInit = $onInit;
    vm.changeParams = changeParams;

    function $onInit() {

      vm.viewContent = $stateParams.content;

    }

    function changeParams(value) {

      $state.go('.', { content: value });

    }

  }

}

export default listMapToggle;
