module.exports={
    "metrics": {
        "city": "brooklyn",
        "state": "ny",
        "total_domains": 42000,
        "total_ad_spend": 3152,
        "alerts": [{
            "type": "duplicates",
            "display_name": "duplicates",
            "amount": 14
        }, {
            "type": "redirect302",
            "display_name": "302's",
            "amount": 22
        }, {
            "type": "contacted",
            "display_name": "Contacted",
            "amount": 51
        }, {
            "type": "no_provider",
            "display_name": "No Provider",
            "amount": 38
        }, {
            "type": "clear",
            "display_name": "Clear",
            "amount": 9
        }, {
            "type": "no_gbp",
            "display_name": "No GBP",
            "amount": 1
        }],
        "organic_search": {
            "volume": 590,
            "results": 40500000
        },
        "paid_search": {
            "cpc": 6.89,
            "competition": 0.95
        }
    }
}
