function domainsOptions($document, $filter, $log, $state, $stateParams, $mdDialog, $q, ModalService, DomainsFactory, SelectedDomainsFactory) {
  'ngInject';

  return {
    restrict: 'E',
    scope: {},
    templateUrl: 'home/domains/domainsOptions/tpl.html',
    bindToController: {
      domains: '=ngModel',
      onExport: '&'
    },
    controller: controller,
    controllerAs: 'vm'
  };

  function controller($scope) {
    'ngInject';

    const vm = this;

    vm.$onInit = $onInit;
    vm.openMenu = openMenu;
    vm.tagDomains = tagDomains;
    vm.getCSVArray = getCSVArray;
    vm.getCSVHeader = getCSVHeader;
    vm.showTagsModal = showTagsModal;
    vm.getCSVFilename = getCSVFilename;
    vm.getDomainObject = getDomainObject;
    vm.markSelectedAsContacted = markSelectedAsContacted;
    vm.moveSelectedToBlackList = moveSelectedToBlackList;

    function $onInit() {

      vm.csvHeader = [
        'DOMAIN NAME',
        'SHOP NAME',
        'STREET',
        'CITY',
        'STATE',
        'POSTAL',
        'COUNTRY',
        'PHONE',
        'STATUS',
        'SETRAFFIC',
        'SCORE',
        'PROVIDER',
        'AD SPEND',
        'CLEAR'
      ];

      vm.csvFilename = 'domains.csv';

    }

    function tagDomains() {

      SelectedDomainsFactory.getDomainIds()
        .then((res) => {

          vm.showTagsModal(res);

        });

    }

    function showTagsModal(domains) {

      if (vm.modalOpen) {
        return;
      }

      return ModalService.showModal({
        controller: function (close) {
          'ngInject';

          // Set flag to true to stop repeated opening
          // TODO: Find out why this is happening
          vm.modalOpen = true;

          // Controller methods
          this.dismissModal = dismissModal;
          this.domains = domains;

          // The close method
          function dismissModal(data) {

            close(data);

          };

          // Close on escape
          $document.on('keyup', (e) => {

            if (e.keyCode === 27) { dismissModal(); }

          });

        },
        controllerAs: 'vm',
        template: '<tags-add class="overlay tags-add" domains="vm.domains" on-close="vm.dismissModal(message)"></tags-add>'
      }).then((modal) => {

        modal.close.then((value) => {

          // Reset the flag so we can open it again
          vm.modalOpen = false;

          $log.debug('Tag(s) added: ' + value);

        });

      });

    }

    function getCSVArray() {

      return vm.onExport()
        // Format each domain to fit the csv header format
        .then((domains) => domains.map(vm.getDomainObject));

    }

    // vm.csvHeader = 'DOMAIN NAME',
    // 'SHOP NAME ',
    // 'STREET',
    // 'CITY',
    // 'STATE',
    // 'POSTAL',
    // 'COUNTRY',
    // 'PHONE',
    // 'STATUS',
    // 'SETRAFFIC',
    // 'SCORE',
    // 'PROVIDER',
    // 'AD SPEND',
    // 'CLEAR'];
    function getDomainObject(domain) {

      console.log(angular.toJson(domain, true));

      var addressValid = domain.addresses &&
        domain.addresses[0];

      var domainCsv = {

        domain_name: domain.domain_name || '',

        shop_name: domain.company || '',

        street: (
          addressValid &&
          domain.addresses[0].street
        ) || '',

        city: (
          addressValid &&
          domain.addresses[0].city
        ) || '',

        state: (
          addressValid &&
          domain.addresses[0].state
        ) || '',

        postal: (
          addressValid &&
          domain.addresses[0].postal
        ) || '',

        country: (
          addressValid &&
          domain.addresses[0].country
        ) || '',

        phone: domain.phone || '',
        status: domain.status || '',
        setraffic: (
          domain.traffic &&
          domain.traffic[0] &&
          $filter('number')(domain.traffic[0].organic)
        ) || '',
        score: $filter('number')(domain.score) || '',
        provider: $filter('capitalize')(domain.provider) || '',
        adspend: $filter('number')(domain.ad_spend) || '',
        clear: domain.clear || 0

      };

      console.log(angular.toJson(domainCsv, true));

      return domainCsv;

    }

    function getCSVHeader() {

      return vm.csvHeader;

    }

    function getCSVFilename() {

      return vm.csvFilename;

    }

    function openMenu($mdOpenMenu, ev) {

      $mdOpenMenu(ev);

    }

    function markSelectedAsContacted() {

      SelectedDomainsFactory.get()
        .then((res) => {

          Object.keys(res).forEach(function (key) {

            DomainsFactory.markAsContacted(res[key].domain_name);

          });

        });

    }

    function moveSelectedToBlackList(ev) {

      // Appending dialog to document.body to cover sidenav in docs app
      var confirm = $mdDialog.confirm()
        .title('Are you sure you want to blacklist these domains?')
        .ariaLabel('Move to blacklist')
        .targetEvent(ev)
        .ok('YES')
        .cancel('NO');

      $mdDialog.show(confirm).then(function () {

        SelectedDomainsFactory.get()
          .then((res) => {

            Object.keys(res).forEach((key) => {

              // Move domain name to black list
              DomainsFactory.moveToBlackList(res[key].domain_name);

            });

          });

      }, function () {

        $log.debug('You cancelled the blacklisting.');

      });

    }

  }

}

export default domainsOptions;
