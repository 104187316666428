function homeRoutes($stateProvider, $urlRouterProvider) {
  'ngInject';

  $urlRouterProvider.when('/', '/domains');

  // Home routes
  $stateProvider
    .state('home', {
      url: '/',
      templateUrl: 'home/tpl.html',
      data: {
        requiresLogin: true
      },
      abstract: true
    });

}

export default homeRoutes;
