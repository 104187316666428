function filtersToChips() {
  'ngInject';

  return filtersToChipsFilter;

  function filtersToChipsFilter(filters) {

    var chips = [];

    chips.push(
      parseLocation(
        filters.city,
        filters.state
      )
    );

    // Bulk Domains
    chips.push(filters.name && {
      type: 'name',
      display: 'Bulk Domain Search',
      value: filters.name
    });

    chips.push(filters.distance && {
      type: 'distance',
      display: filters.distance + ' mile' + (
        filters.distance > 1 ? 's' : ''
      ),
      value: filters.distance
    });

    chips.push(filters.traffic && {
      type: 'traffic',
      display: '> ' + filters.traffic,
      value: filters.traffic
    });

    // Array.prototype.push.apply(chips, parseList(filters.tag, 'tag'));

    chips.push(filters.tag && {
      type: 'tag',
      display: filters.tag,
      value: filters.tag
    });

    chips.push(filters.contacted && {
      type: 'contacted',
      display: filters.contacted == '1' ? 'Contacted' : 'Not Contacted',
      value: filters.contacted
    });

    chips.push(filters.https && {
      type: 'https',
      display: filters.https == '1' ? 'HTTPS' : 'No HTTPS',
      value: filters.https
    });

    Array.prototype.push.apply(chips, parseList(filters.alerts, 'alert'));

    Array.prototype.push.apply(chips, parseList(filters.status, 'status'));

    Array.prototype.push.apply(chips, parseList(filters.duplicates, 'duplicates', 'Duplicates'));

    Array.prototype.push.apply(chips, parseList(filters.clear, 'clear', 'Clear'));

    Array.prototype.push.apply(chips, parseList(filters.provider, 'provider'));

    Array.prototype.push.apply(chips, parseList(filters.text, 'text'));

    // Remove falsy chips
    return chips.filter((chip) => chip);

  }

  function parseLocation(city, state) {

    var cityState = '';

    // Separate city and state with a comma-space if city is defined
    cityState = city && state ? city + ', ' + state : state;

    return cityState && {
      type: 'location',
      display: cityState,
      value: {
        city: city,
        state: state
      }
    };

  }

  function parseList(list, type, display) {

    var listArr = [];

    if (!list || !type) {

      return;

    }

    listArr = list.split(',')
      .filter((item) => item)
      .map((item) => {

        var itemDisplay = display;

        if (typeof display == 'undefined') {

          itemDisplay = item;

        }

        return {
          type: type,
          display: itemDisplay,
          value: item
        };

      });

    return listArr;

  }

}

export default filtersToChips;
