function tagsRoutes($stateProvider) {
  'ngInject';

  const urlParams = '?sort&sort_order&city&state&country&status&duplicates&clear&contacted&traffic&distance&text&provider&alerts&tag&name&content&editTags';

  // Tags routes
  $stateProvider
    .state('home.tags', {
      url: 'tags' + urlParams,
      params: {
        tab: null,
        tag: null,
        text: null,
        sort: 'id',
        city: null,
        name: null,
        state: null,
        clear: null,
        alerts: null,
        country: null,
        traffic: null,
        status: null,
        distance: null,
        provider: null,
        content: 'list',
        contacted: null,
        duplicate: null,
        showFilters: null,
        sort_order: 'desc',
        content: 'list',
        editTags: null
      },
      templateUrl: 'home/tags/tpl.html',
      data: {
        displayName: 'Tags'
      }
    })
    .state('home.tags.details', {
      url: '/:domainName',
      views: {
        'rightpanel@home.tags': {
          template: '<domain-details flex="100" layout="column" layout-align="start stretch"></domain-details>'
        }
      }
    });

}

export default tagsRoutes;
