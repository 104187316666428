function blacklistRoutes($stateProvider) {
  'ngInject';

  const urlParams = '?sort&sort_order&city&state&country&status&duplicates&clear&contacted&traffic&distance&text&provider&alerts&tag&name&content',
    blacklistTemplate = '<blacklist-list flex="100" layout="column" layout-align="start stretch"></blacklist-list>';

  // Locations routes
  $stateProvider
    .state('home.blacklist', {
      url: 'blacklist' + urlParams,
      params: {
        // tab: null,
        // tag: null,
        text: null,
        // sort: 'id',
        // city: null,
        // name: null,
        // state: null,
        // clear: null,
        // alerts: null,
        // country: null,
        // traffic: null,
        // status: null,
        // distance: null,
        // provider: null,
        // content: 'list',
        // contacted: null,
        // duplicate: null,
        // showFilters: null,
        // sort_order: 'desc'
      },
      reloadOnSearch: true,
      templateUrl: 'home/blacklist/tpl.html'
    });
}

export default blacklistRoutes;
