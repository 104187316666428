import angular from 'angular';

// angular modules
import './app.env';
import run from './app.run';
import config from './app.config';

// Third party
import 'ng-csv';
import 'ngstorage';
import 'angular-jwt';
import 'auth0-angular';
import 'angular-scroll';
import 'angular-material';
import 'angular-sanitize';
import '@uirouter/angularjs';
import 'ng-infinite-scroll';
import 'angular-google-chart';
import 'angular-modal-service';
import 'angular-simple-logger';
import 'angular-material-data-table';

// local modules
import './templates';
import './home';

// create and bootstrap application
const requires = [
  'templates',
  'ufn.lost.env',
  'ufn.lost.home',
  'ui.router',
  'md.data.table',
  'ngMaterial',
  'infinite-scroll',
  'angularModalService',
  'auth0',
  'angular-jwt',
  'googlechart',
  'ngSanitize',
  'nemLogging',
  'ngStorage',
  'ngCsv',
  'duScroll'
];

// mount on window for testing
const app = angular.module('ufn.lost', requires);
window.app = app;

app.config(config);

app.run(run);

angular.bootstrap(document, ['ufn.lost'], {
  strictDi: true
});