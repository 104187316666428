function locationsRightPanel() {

  return {
    restrict: 'E',
    scope: {},
    templateUrl: 'home/locations/locationsRightPanel/tpl.html',
    bindToController: {},
    controller: controller,
    controllerAs: 'vm'
  };

  function controller($stateParams) {
    'ngInject';

    const vm = this;

    vm.$onInit = $onInit;

    function $onInit() {

      // Take the state params and display metrics or providers
      vm.currentView = $stateParams.tab === 'metrics' ? 1 : 0;

    }

  }

}

export default locationsRightPanel;
