import angular from 'angular';

// Directive
import persistModel from './directive';

// Factory
import PersistModelFactory from './factory';

const persistModelModule = angular.module('ufn.lost.persist', []);

// Directives
persistModelModule.directive('persistModel', persistModel);

// Factory
persistModelModule.factory('PersistModelFactory', PersistModelFactory);

export default persistModelModule;
