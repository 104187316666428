function blacklistList() {

  return {
    restrict: 'E',
    scope: {},
    templateUrl: 'home/blacklist/blacklistList/tpl.html',
    bindToController: {
      domains: '=ngModel',
      isLoading: '=',
      selected: '='
    },
    controller: controller,
    controllerAs: 'vm',
    link: link
  };

  function controller($state, $stateParams, SelectedDomainsFactory) {
    'ngInject';

    const vm = this;

    vm.$onInit = $onInit;
    vm.selectAll = selectAll;
    vm.select = select;

    function $onInit() {

      vm.allSelected = false;
      vm.selectedDomains = [];
      vm.$stateParams = $stateParams;
      vm.$state = $state;

    }

    function select(val, domainName) {

      var indexOfName = vm.selected && vm.selected.indexOf(domainName);

      if (val && indexOfName === -1) {

        vm.selected.push(domainName);

      } else if (indexOfName !== -1) {

        vm.selected.splice(indexOfName, 1);

      }

    }

    function selectAll() {

      vm.selected = vm.allSelected ? vm.domains : [];
      vm.selectedDomains.forEach((val, i) => vm.selectedDomains[i] = vm.allSelected);

    }

  }

  function link(scope, elem) {
    'ngInject';

    const vm = scope.vm;

    scope.$watchCollection('vm.domains', (newVal) => {

      if (!newVal) {
        return;
      }

      // Start off with all false chckboxes
      vm.selectedDomains = [];

      vm.domains.forEach((domain, i) => vm.selectedDomains[i] = false);

    });

  }

}

export default blacklistList;
