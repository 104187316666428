function lostToggleButtonGroup() {

  return {
    restrict: 'E',
    templateUrl: 'home/lostToggleButtonGroup/tpl.html',
    scope: {},
    bindToController: {
      displayProp: '@?',
      buttons: '=',
      onToggle: '&?',
      toggledButtons: '=?'
    },
    controller: controller,
    controllerAs: 'vm',
    link: link
  };

  function controller() {
    'ngInject';

    const vm = this;

    vm.$onInit = $onInit;
    vm.toggle = toggle;

    function $onInit() {

      vm.toggledButtons = vm.toggledButtons || [];
      vm.displayProp = vm.displayProp || 'name';

    }

    function toggle(button) {

      // Toggle off
      if (vm.toggledButtons.find(
        // angular.equals ignores $ prefix,
        // so $$hashKey will be ignored
        (e) => angular.equals(e, button)
      )) {

        vm.toggledButtons = vm.toggledButtons.filter(
          (e) => !angular.equals(e, button)
        );

      // Toggle on
      } else {

        vm.toggledButtons.push(button);

      }

      return vm.onToggle && vm.onToggle({toggledButtons: vm.toggledButtons});

    }

  }

  function link(scope, elem) {

    scope.$watch('vm.toggledButtons', (newVal) => {

      // Async apply ensures md-chips finishes digests
      scope.$applyAsync(() => {

        toggleButtonStyle(newVal);

      });

    }, true);

    function toggleButtonStyle(toggledButtons) {

      // Get all md-chips
      var chipElems = Array.prototype.slice.call(
        elem[0].querySelectorAll('md-chip')
      );

      chipElems.forEach((chipElem) => {

        var classAction = '';

        // Prevent runtime errors when no scope.
        // Haven't seen this cause behavior problems yet.
        if (!angular.element(chipElem).scope()) {

          return;

        }

        // Get this chip
        var chip = angular.element(chipElem).scope().$chip;

        // Chip is toggled on
        if (toggledButtons.find(
          // angular.equals ignores $ prefix,
          // so $$hashKey will be ignored
          (e) => angular.equals(e, chip)
        )) {

          classAction = 'add';

        // Chip is toggled off
        } else {

          classAction = 'remove';

        }

        chipElem.classList[classAction]('toggled');

      });

    }

  }

}

export default lostToggleButtonGroup;
