module.exports={
  "demographics" : {
    "location":{
        "city":"brooklyn",
        "state":"ny",
    },
    "population":2592000,
    "size":96.91,
    "median_age":33,
    "avg_income":64217,
    "sales_tax_rate":8.875,
    "avg_floral_arrangement_cost":62
  }
}
