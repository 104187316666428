function GoogleTagManagerFactory() {
  'ngInject';

  var service = {
    bootstrap: bootstrap,
    pushToDataLayer: pushToDataLayer
  };

  return service;

  /**
   * Bootstraps the google tag manager script.
   *
   * @param  {string} containerId - The id for the google tag manager container.
   * @param  {string} envParams - The environment parameters for this container.
   * @return {void}
   */
  function bootstrap(containerId, envParams = '') {

    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', containerId + envParams);

  }

  function pushToDataLayer(obj = {}) {

    console.log('dataLayer', obj);

    var dataLayer = window.dataLayer = window.dataLayer || [];
    dataLayer.push(obj);

  }

}

export default GoogleTagManagerFactory;