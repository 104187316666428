import angular from 'angular';

// Routes
import '@uirouter/angularjs';
import blacklistRoutes from './routes';

// Factories
import BlacklistFactory from './factory';

// Filters
import chipsToFilters from '../domains/search/filters/chipsToFilters';
import filtersToChips from '../domains/search/filters/filtersToChips';

// Directives
import blacklistContent from './blacklistContent/directive';
import blacklistOptions from './blacklistOptions/directive';
import blacklistList from './blacklistList/directive';

const blacklistModule = angular.module('ufn.lost.home.blacklist', [
  'ui.router'
]);

blacklistModule.config(blacklistRoutes);

/*----------  Directives  ----------*/

blacklistModule.directive('blacklistContent', blacklistContent);
blacklistModule.directive('blacklistOptions', blacklistOptions);
blacklistModule.directive('blacklistList', blacklistList);

/*----------  Filters  ----------*/

blacklistModule.filter('chipsToFilters', chipsToFilters);
blacklistModule.filter('filtersToChips', filtersToChips);

/*----------  Factories  ----------*/

blacklistModule.factory('BlacklistFactory', BlacklistFactory);

export default blacklistModule;
