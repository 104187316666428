function locationsContent() {

  return {
    restrict: 'E',
    scope: {},
    templateUrl: 'home/locations/locationsContent/tpl.html',
    bindToController: {},
    controller: controller,
    controllerAs: 'vm',
    link: link
  };

  function controller($state, $stateParams, LocationsFactory) {
    'ngInject';

    const vm = this;

    vm.$onInit = $onInit;
    vm.getNextPage = getNextPage;
    vm.setLocation = setLocation;
    vm.setSort = setSort;

    function $onInit() {

      vm.locations = [];
      vm.page = 1;
      vm.limit = 25;
      vm.sort = (
        $stateParams.sort && (
          $stateParams.sort_order === 'desc' ? '-' : ''
        ) + $stateParams.sort
      ) || 'city';
      vm.disablePaging = false;

      vm.getNextPage();

    }

    function getNextPage() {

      // Paging in progress or end of list
      if (vm.disablePaging) {
        return;
      }

      vm.disablePaging = true;

      var locationsParams = {
        page: vm.page,
        limit: vm.limit,
        sort: $stateParams.sort,
        sort_order: $stateParams.sort_order
      };

      LocationsFactory.get(locationsParams)
        .then((res) => {

          vm.locations = vm.locations.concat(res.locations);
          vm.page = res._page && res._page.next;

          if (vm.page) {

            vm.disablePaging = false;

          }

        });

    }

    function setLocation(location) {

      // console.log(location);

      return $state.go('.domains', {
        city: location.city,
        state: location.state_code,
        locationId: location.id,
        lat: location.lat,
        lng: location.lon,
        sort: 'traffic'
      });

    }

    function setSort(sort) {

      $state.go('.', {
        // Sort order is expected as "-sortBy" or "sortBy"
        // block required around ternary to set undefined when sort undefined
        sort_order: sort && (sort.startsWith('-') ? 'desc' : 'asc'),
        // API doesn't support "-sortBy"
        sort: sort && sort.replace(/^-/, '')
      }, {
        inherit: false
      });

    }

  }

  function link(scope) {

    const vm = scope.vm;

    // Set sort in the filters
    scope.$watch('vm.sort', vm.setSort);

  }

}

export default locationsContent;
