import angular from 'angular';

// Routes
import '@uirouter/angularjs';
import locationsRoutes from './routes';

// Factories
import LocationsFactory from './factory';
import DomainsFactory from '../domains/factory';

// Locations
import locationsContent from './locationsContent/directive';
import locationsRightPanel from './locationsRightPanel/directive';

// Locations > Domains
import locationsDomainsRightPanel from './locationsDomainsRightPanel/directive';

// Search
import locationsSearch from './locationsSearch/directive';

// Locations > list
import locationsList from './locationsList/directive';

// Metrics
import locationsMetrics from './locationsMetrics/directive';
import locationsDomainsMetrics from './locationsDomainsMetrics/directive';

// Providers
import locationsProviders from './locationsProviders/directive';

// Demographics
import locationsDomainsDemographics from './locationsDomainsDemographics/directive';

const locationsModule = angular.module('ufn.lost.home.locations', [
  'ui.router'
]);

locationsModule.config(locationsRoutes);

// Factories
locationsModule.factory('LocationsFactory', LocationsFactory);
locationsModule.factory('DomainsFactory', DomainsFactory);

// Layout content - Locations
locationsModule.directive('locationsContent', locationsContent);
locationsModule.directive('locationsRightPanel', locationsRightPanel);

// Layout content - Locations - list
locationsModule.directive('locationsList', locationsList);

// Layout content - Locations - Domains
locationsModule.directive('locationsDomainsRightPanel', locationsDomainsRightPanel);

// Locations Search
locationsModule.directive('locationsSearch', locationsSearch);

// Metrics
locationsModule.directive('locationsMetrics', locationsMetrics);
locationsModule.directive('locationsDomainsMetrics', locationsDomainsMetrics);

// Providers
locationsModule.directive('locationsProviders', locationsProviders);

// Demographics
locationsModule.directive('locationsDomainsDemographics', locationsDomainsDemographics);

export default locationsModule;
