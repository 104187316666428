function tagsSearch() {

  return {
    restrict: 'EA',
    templateUrl: 'home/tags/search/tpl.html',
    scope: {},
    bindToController: {
      edit: '=',
      tags: '=ngModel'
    },
    controller: controller,
    controllerAs: 'vm'
  };

  function controller($log, $filter, TagsFactory) {
    'ngInject';

    const vm = this;

    vm.search = search;
    vm.getTags = getTags;

    function search(query) {

      vm.tags = $filter('filter')(vm.tags, query);

    }

    function getTags() {

      TagsFactory.getList()
        .then((res) => {

          vm.tags = res;

        });

    }

  }

}

export default tagsSearch;
