import angular from 'angular';
import '@uirouter/angularjs';

import tagsRoutes from './routes';
import TagsFactory from './factory';
import tagsContent from './tagsContent/directive';
import tagsList from './list/directive';
import tagsAdd from './add/directive';
import addTagIcon from './addTagIcon/directive';
import tagsSearch from './search/directive';
import tagsSearchInput from './search/input/directive';

const tagsModule = angular.module('ufn.lost.home.tags', [
    'ui.router'
]);

tagsModule.config(tagsRoutes);

tagsModule.factory('TagsFactory', TagsFactory);

// Directives
tagsModule.directive('tagsContent', tagsContent);
tagsModule.directive('tagsSearch', tagsSearch);
tagsModule.directive('tagsSearchInput', tagsSearchInput);
tagsModule.directive('tagsList', tagsList);
tagsModule.directive('tagsAdd', tagsAdd);
tagsModule.directive('addTagIcon', addTagIcon);

export default tagsModule;
