function tagsContent($log, $state, $stateParams) {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: 'home/tags/tagsContent/tpl.html',
    scope: {},
    bindToController: {},
    controller: controller,
    controllerAs: 'vm'
  };

  function controller(TagsFactory) {
    'ngInject';

    const vm = this;

    vm.$onInit = $onInit;

    function $onInit() {

      vm.$stateParams = $stateParams;
      vm.edit = false;

      vm.chosenChips = [];

      vm.filters = {
        tag: $stateParams.tag
      };

      TagsFactory.getList()
        .then((res) => {

          vm.tags = res;

        });

    }



  }

}

export default tagsContent;
