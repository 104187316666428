module.exports={
    "competitors": [{
        "domain": "13thaveflorist.com",
        "provider": "FTD",
        "volume": 710,
        "cpc": 3.47
    },
    {
        "domain": "13thaveflorist.com",
        "provider": "FTD",
        "volume": 710,
        "cpc": 3.47
    },
    {
        "domain": "13thaveflorist.com",
        "provider": "FTD",
        "volume": 710,
        "cpc": 3.47
    },
    {
        "domain": "13thaveflorist.com",
        "provider": "FTD",
        "volume": 710,
        "cpc": 3.47
    }]
}
