function domainDetails() {

  return {
    restrict: 'E',
    templateUrl: 'home/domains/domainDetails/tpl.html',
    scope: {},
    bindToController: {},
    controller: controller,
    controllerAs: 'vm'
  };

  function controller(DomainsFactory, $stateParams, $state) {
    'ngInject';

    const vm = this;

    vm.$onInit = $onInit;
    vm.tabState = tabState;

    function $onInit() {

      if (!$stateParams.domainName) {

        $state.go('^', {});

      }

      DomainsFactory.get($stateParams.domainName)
        .then(function (res) {

          vm.domain = res;

        });

      var view = {
        'info': 0,
        'notes': 1,
        'activity': 2
      };

      vm.currentView = view[$stateParams.tab] || 0;

    }

    function tabState(state) {

      $state.go('.', {
        tab: state
      }, {
        notify: false
      });

    }

  }

}

export default domainDetails;
