function tel() {
    'ngInject';

    return function(input) {

        if (!input) {
            return '';
        }

        var value = input.toString().trim().replace(/^\+/, '');

        if (value.match(/[^0-9]/)) {
            return input;
        }

        var country, city, number, ext;

        if (value.length === 10) {

            country = 1;
            city = value.slice(0, 3);
            number = value.slice(3);
            ext = '';

        } else if (value.length >= 11) {

            country = 1;
            city = value.slice(0, 3);
            number = value.slice(3, 10);
            ext = ' ext. ' + value.slice(10);

        } else {

            return input;

        }

        if (country == 1) {

            country = '';

        }

        number = number.slice(0, 3) + '-' + number.slice(3);

        return (country + ' (' + city + ') ' + number + ext).trim();

    };

}

export default tel;
