function locationsMetrics() {

    return {
        restrict: 'E',
        scope: {},
        templateUrl: 'home/locations/locationsMetrics/tpl.html',
        bindToController: {},
        controller: controller,
        controllerAs: 'vm'
    };

    function controller(LocationsFactory) {
        'ngInject';

        const vm = this;

        vm.$onInit = $onInit;

        function $onInit() {

            LocationsFactory.getMetrics()
                .then(function(res) {

                    vm.metrics = res;

                });

        }

    }

}

export default locationsMetrics;
