module.exports={
    "metrics": [{
        "name": "all",
        "total_domains": 42000,
        "traffic": 1734,
        "cost_per_click": 2.91,
        "ad_spend": 3152
    }, {
        "name": "usa",
        "total_domains": 42000,
        "traffic": 1734,
        "cost_per_click": 2.91,
        "ad_spend": 3152
    }, {
        "name": "ca",
        "total_domains": 42000,
        "traffic": 1734,
        "cost_per_click": 2.91,
        "ad_spend": 3152
    }]
}
