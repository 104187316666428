function accountAddressTypeahead() {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: 'home/accountAddressTypeahead/tpl.html',
    scope: {},
    bindToController: {
      address: '=ngModel',
      onSubmit: '&'
    },
    controller: controller,
    controllerAs: 'vm'
  };

  function controller($http, $q, MapsFactory) {
    'ngInject';

    const vm = this;

    // Methods
    vm.$onInit = $onInit;
    vm.saveAddress = saveAddress;
    vm.returnAddress = returnAddress;
    vm.locationSearch = locationSearch;

    function $onInit() {

      vm.savedAddress = {};

    }

    function returnAddress() {

      console.log(vm.savedAddress);

      let address = {
        city: vm.savedAddress.city,
        // country: vm.savedAddress.country,
        lat: vm.savedAddress.lat,
        lon: vm.savedAddress.lon,
        postal: vm.savedAddress.postal,
        state: vm.savedAddress.state,
        street1: vm.savedAddress.street_1
      };

      return vm.onSubmit({
        address: address
      });

    }

    function locationSearch(query) {

      // Prevent querying on undefined (invalid/empty/etc)
      if (!query) {

        vm.address.lat = '';
        vm.address.lon = '';

        return;

      }

      // Add filters to query text for async filter
      return MapsFactory.getLocations(query, false)
        .then((data) => $q.when(data));

    }

    function saveAddress(address) {

      if (!address || !address.terms || !address.description) {
        return;
      }

      MapsFactory.geocodeAddress({
          address: address.description
        })
        .then(MapsFactory.parseGeocodeAddress)
        .then((address) => Object.assign(vm.savedAddress, address));

    }

  }

}

export default accountAddressTypeahead;
