function ProvidersFactory($log, $http, $q, ENV) {
  'ngInject';

  var service = {
    get: get,
    getHistory: getHistory
  };

  return service;

  function getHistory(provider) {

    return $http.get(ENV.API_BASE_URL + '/providers/history/' + provider)
      .then(function (res) {

        return res.data.sem_lmetrics && $q.when(res.data.sem_lmetrics[0]);

      });

  }

  function get() {

    return $http.get(ENV.API_BASE_URL + '/providers/')
      .then(function (res) {

        return $q.when(res.data.providers);

      });

  }

}

export default ProvidersFactory;
