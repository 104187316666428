function config($logProvider, $locationProvider, $urlRouterProvider, $stateProvider, $mdThemingProvider, authProvider, jwtInterceptorProvider, $httpProvider, ENV) {
  'ngInject';

  /*----------  $locationProvider  ----------*/

  $locationProvider.hashPrefix('!');
  $locationProvider.html5Mode(true);

  $urlRouterProvider.otherwise('/domains');

  /*----------  $mdThemingProvider  ----------*/

  $mdThemingProvider.theme('default')
    .primaryPalette('grey')
    .accentPalette('blue', {
      'default': 'A700'
    })
    .warnPalette('red');

  $mdThemingProvider.theme('ufn.lost.menu')
    .dark();

  $mdThemingProvider.definePalette('ufn.lost.menu', {
    // 300, 400, 500, 600, 700, 800, 900, A100, A200, A400, A700
    '50': '3d3d3d',
    '100': '424242',
    '200': '9e9e9e',
    // Only needed three colors, but mdtheming provider
    // requires all these to be filled out
    '300': 'e53935',
    '400': 'd32f2f',
    '500': 'c62828',
    '600': 'e53935',
    '700': 'd32f2f',
    '800': 'c62828',
    '900': 'b71c1c',
    'A100': 'ff8a80',
    'A200': 'ff5252',
    'A400': 'ff1744',
    'A700': 'd50000',
    // End unused colors
    'contrastDefaultColor': 'dark',
    'contrastDarkColors': [
      '50',
      '100',
      '200'
    ],
    'contrastLightColors': undefined
  });

  /*----------  $stateProvider  ----------*/

  $stateProvider.state('login', {
    url: '/login',
    onEnter: ($state, auth) => {

      auth.signin({},
        (profile, idToken, accessToken, state, refreshToken) => {

          localStorage.setItem('profile', JSON.stringify(profile));
          localStorage.setItem('token', idToken);

          return $state.go('home.domains');

        }
      );

    }
  });

  $stateProvider.state('logout', {
    url: '/logout',
    controller: ($state, auth) => {

      auth.signout();
      localStorage.removeItem('profile');
      localStorage.removeItem('token');
      $state.go('login');

    }
  });

  /*----------  Auth Provider  ----------*/

  authProvider.init({
    domain: ENV.AUTH0_DOMAIN,
    clientID: ENV.AUTH0_CLIENT_ID,
    loginState: 'login' // matches login state
  });

  authProvider.on('loginFailure', ($state) => {

    console.log('Error logging in');
    $state.go('login');

  });

  authProvider.on('authenticated', (auth, GoogleTagManagerFactory) => {
    'ngInject';

    console.log('Authenticated');

    auth.profilePromise.then((profile) => {
      GoogleTagManagerFactory.pushToDataLayer({
        event: 'lv.user.authenticated',
        user: profile
      });
    });

  });

  /*----------  Misc. Providers  ----------*/

  jwtInterceptorProvider.tokenGetter = () => {

    return localStorage.getItem('token');

  }

  $httpProvider.interceptors.push('jwtInterceptor');

  // Disable log.debug if not in development env.
  if (ENV.API_BASE_URL === 'https://lost.ufn.com') {

    $logProvider.debugEnabled(false);

  }

}

export default config;