function blacklistContent($document, $log, $state, $stateParams, $mdDialog, $q, BlacklistFactory) {
  'ngInject';

  return {
    restrict: 'E',
    scope: {},
    templateUrl: 'home/blacklist/blacklistContent/tpl.html',
    bindToController: {
      showDistanceFilter: '='
    },
    controller: controller,
    controllerAs: 'vm',
    link: link
  };

  function controller($scope) {
    'ngInject';

    const vm = this;

    vm.$onInit = $onInit;
    vm.getDomains = getDomains;

    function $onInit() {

      vm.domains = [];
      vm.selected = [];
      vm.isPageLoading = true

      vm.showRowsClass = '';

      vm.$stateParams = $stateParams;

      vm.filters = Object.assign({}, {
        text: vm.$stateParams.text
      });

      vm.getDomains(vm.filters)
        .then((res) => {

          vm.domains = res.blacklist;

        })
        .finally(() => vm.isPageLoading = false);

    }

    function getDomains(filterParams) {

      return BlacklistFactory.getList(filterParams || vm.filters);

    }

  }

  function link(scope) {

    const vm = scope.vm;

    scope.$watch('vm.filters', (filters) => {

      // This prevents us from having to explicitly add
      // all non-filter parameters onto the search
      delete $stateParams.text;

      angular.extend($stateParams, filters);

      $state.go($state.current, {});

    });

  }
}

export default blacklistContent;
