/**
 * @ngdoc object
 * @name ufn.pos.directive:persistModel
 *
 * @description Deep-watches and persists a given model to localStorage
 *
 */

function persistModel(PersistModelFactory) {
  'ngInject';

  return {
    restrict: 'EA',
    scope: false,
    link: link
  };

  function link(scope, elem, attrs) {

    // Watch the scope value and save changes to persistent store
    // attrs.persistModel will evaluate to a string that resolves on scope
    scope.$watch(attrs.persistModel, function(modelVal) {

      // Save to local storage
      PersistModelFactory.setPersistedModel(attrs.persistModel, modelVal);

    }, true);

  }

}

export default persistModel;
