function mapRoutes($stateProvider) {
  'ngInject';

  // Locations routes
  $stateProvider
    .state('home.map', {
      url: 'map?city&state&country&status&duplicates&clear&traffic&distance&text&provider&alerts&tag&content',
      params: {
        city: null,
        state: null,
        country: null,
        traffic: null,
        distance: null,
        text: null,
        provider: null,
        alerts: null,
        tag: null,
        // showFilters: null,
        status: null,
        duplicate: null,
        clear: null,
        content: null
      },
      reloadOnSearch: true,
      template: '<map-content flex layout="row"></map-content>',

    })
    .state('home.map.details', {
      url: '/:domainName',
      views: {
        'rightpanel@home.map': {
          template: '<domain-details flex="100" layout="column" layout-align="start stretch"></domain-details>'
        }
      }
    });
}

export default mapRoutes;
