function run($location, $rootScope, $state, $stateParams, auth, jwtHelper, ENV, GoogleTagManagerFactory, MapsFactory, SelectedDomainsFactory) {
  'ngInject';

  MapsFactory.bootstrap(ENV.GOOGLE.MAPS_JAVASCRIPT_API_KEY);

  GoogleTagManagerFactory.bootstrap(ENV.GOOGLE.TAG_MANAGER_CONTAINER_ID);

  $rootScope.$on('$locationChangeStart', function (event, toState, toParams, fromState, fromParams, options) {

    var token = localStorage.getItem('token');

    if (!token || jwtHelper.isTokenExpired(token)) {

      return $location.path('/login');

    }

    if (!auth.isAuthenticated) {

      return auth.authenticate(JSON.parse(localStorage.getItem('profile')), token)
        .catch(function (err) {

          return $location.path('/login');

        });

    }

  });

  $rootScope.$on('$stateChangeSuccess', function () {

    // Clear any selected domains
    SelectedDomainsFactory.removeAll();

  });

  $rootScope.$on('$locationChangeSuccess', () => {

    GoogleTagManagerFactory.pushToDataLayer({
      event: 'lv.virtualPageView',
      attributes: {
        virtualPage: location
          && (location.pathname || '') + (location.search || ''),
        virtualTitle: document
          && document.title
      }
    });

  });

  // Attach state and stateparams to rootscope
  $rootScope.$state = $state;
  $rootScope.$stateParams = $stateParams;

}

export default run;
