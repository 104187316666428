function mdChips() {
  'ngInject';

  return {
    restrict: 'E',
    link: link
  };

  function link(scope, elem, attrs) {

    var inputElement;

    // Only link to scope if mdBindInput is present
    if (attrs.mdBindInput) {

      scope.$applyAsync(() => {

        // Find the input within the mdChips directive (after compiled)
        inputElement = elem[0].querySelector('input');

        // on keyup...
        inputElement.addEventListener('keyup', (e) => {

          // $log.debug(scope[attrs.mdBindInput], attrs.mdBindInput)

          // Use mdBindInput attribute value to find related value in scope
          scope.vm.query = inputElement.value;

          scope.$apply();

        });

      });

    };

  }

}

export default mdChips;
