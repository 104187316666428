import domainsTestData from './domains.json';
import domainsActivityTestData from './activity/activity.json';
import domainsCompetitorsTestData from './info/domainCompetitors.json';

function DomainsFactory($log, $http, $q, ENV) {
  'ngInject';

  var service = {
    get: get,
    getList: getList,
    getCompetitors: getCompetitors,
    markAsContacted: markAsContacted,
    moveToBlackList: moveToBlackList,
    getActivity: getActivity,
    getTraffic: getTraffic,
    extendMapView: extendMapView,
    updateTraffic: updateTraffic,
    updateAddress: updateAddress
  };

  return service;

  function updateAddress(data) {

    return $http.post(ENV.API_BASE_URL + '/domains/', data)
      .then((res) => {

        return $q.when(res.data.domain_id);

      });

  }

  function get(domainName) {

    return $http.get(ENV.API_BASE_URL + '/domains/' + domainName)
      .then((res) => {

        return $q.when(res.data.domains[0]);

      });

  }

  function getActivity(domainName) {

    return $http.get(ENV.API_BASE_URL + '/domains/activity/' + domainName)
      .then((res) => {

        return $q.when(res.data.domains_activity);

      });

  }

  function getList(params) {

    return $http.get(ENV.API_BASE_URL + '/domains/', { params: params })
      .then((res) => {

        return $q.when(res.data);

      });

  }

  function getTraffic(domainName) {

    return $http.get(ENV.API_BASE_URL + '/domains/traffic/' + domainName)
      .then((res) => {
        // $log.debug(res.data);
        return $q.when(res.data.domains_traffic[0]);

      });

  }

  function updateTraffic(domainName) {

    return $http.post(ENV.API_BASE_URL + '/domains/traffic/' + domainName)
      .then((res) => {

        return $q.when(res.data);

      });

  }

  function getCompetitors(domainName) {

    return $http.get(ENV.API_BASE_URL + '/domains/competitors/' + domainName)
      .then((res) => {

        return $q.when(res.competitors);

      });

  }

  function markAsContacted(domainName) {

    return $http.post(ENV.API_BASE_URL + '/domains/' + domainName + '/contacted')
      .then((res) => {

        return $q.when(res.data);

      });

  }

  function moveToBlackList(domainName) {

    return $http.post(ENV.API_BASE_URL + '/domains/' + domainName + '/blacklist')
      .then((res) => {

        return $q.when(res.tags);

      });

  }

  function extendMapView(domains) {

    return domains.map((domain) => {

      if (domain.addresses &&
        domain.addresses[0] &&
        angular.isDefined(domain.addresses[0].lat) &&
        angular.isDefined(domain.addresses[0].lon)) {

        domain.lat = domain.addresses[0].lat;
        domain.lon = domain.addresses[0].lon;

      }

      return domain;

    });

  }

}

export default DomainsFactory;
