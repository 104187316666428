import domainsNotesTestData from './notes.json';

function NotesFactory($http, $q, ENV) {
  'ngInject';

  var service = {
    get: get,
    addNote: addNote,
    editNote: editNote,
    deleteNote: deleteNote
  };

  return service;

  function get(domainName) {

    return $http.get(ENV.API_BASE_URL + '/notes/' + domainName)
      .then(function (res) {

        return $q.when(res.data.notes);

      })

  }

  function editNote(noteId, text) {

    return $http.put(ENV.API_BASE_URL + '/notes/' + noteId, { text: text })
      .then(function (res) {

        return $q.when(res);

      });

  }

  function addNote(domainName, noteText) {

    var userProfile = JSON.parse(localStorage.getItem('profile'));

    return $http.post(ENV.API_BASE_URL + '/notes/' + domainName, {
        'text': noteText,
        'user': userProfile.name
      })
      .then(function (res) {

        return $q.when(res.data);

      });

  }

  function deleteNote(noteId) {

    return $http.delete(ENV.API_BASE_URL + '/notes/' + noteId)
      .then(function (res) {

        return $q.when(res);

      });

  }

}

export default NotesFactory;
