function JavaScriptFactory($q) {
  'ngInject';

  var service = {
    inject: inject
  };

  return service;

  function inject(src) {

    var script = document.createElement('script');
    var loaded;

    var deferred = $q.defer();

    script.setAttribute('src', src);

    script.onreadystatechange = script.onload = onLoad;

    document.getElementsByTagName('body')[0]
      .appendChild(script);

    return deferred.promise;

    function onLoad() {

      if (!loaded) {

        deferred.resolve();

      }

      loaded = true;

    }

  }

}

export default JavaScriptFactory;
