function domainsSearch($filter, $location, $stateParams) {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: 'home/domains/search/tpl.html',
    scope: {},
    bindToController: {
      filters: '=',
      isChipReadonly: '&?',
      showDistanceFilter: '=',
      sort: '='
    },
    controller: controller,
    controllerAs: 'vm',
    link: link
  };

  function controller($document, $state, ModalService) {
    'ngInject';

    const vm = this;

    vm.$onInit = $onInit;
    vm.setSort = setSort;
    vm.showBulkDomainsOverlay = showBulkDomainsOverlay;
    vm.toggleShowFilters = toggleShowFilters;

    function $onInit() {

      vm.chips = [];
      vm.showFilters = false;

    }

    function setSort(sort) {

      $state.go('.', {
        // Sort order is expected as "-sortBy" or "sortBy"
        // block required around ternary to set undefined when sort undefined
        sort_order: sort && (sort.startsWith('-') ? 'desc' : 'asc'),
        // API doesn't support "-sortBy"
        sort: sort && sort.replace(/^-/, '')
      });

    }

    function showBulkDomainsOverlay() {

      return ModalService.showModal({
          controller: function (close) {
            'ngInject';

            // Controller methods
            this.dismissModal = dismissModal;
            this.domains = vm.filters.name;

            // The close method
            function dismissModal(data) {

              close(data);

            };

            // Close on escape
            $document.on('keyup', (e) => {

              if (e.keyCode === 27) { dismissModal(); }

            });

          },
          controllerAs: 'vm',
          template: '<bulk-domains-overlay class="overlay" domains="vm.domains" on-close="vm.dismissModal(message)"></bulk-domains-overlay>'
        })
        .then((modal) => {

          modal.close.then((value) => {

            vm.filters.name = value && value.message.join(',');

          });

        });

    }

    function toggleShowFilters() {

      vm.showFilters = !vm.showFilters
        // $location.search('showFilters', vm.showFilters);

    }

  }

  function link(scope) {

    const vm = scope.vm;

    // Set sort in the filters
    scope.$watch('vm.sort', vm.setSort);

    scope.$watch('vm.filters', (newVal, oldVal) => {

      var chips = $filter('filtersToChips')(newVal);

      vm.chips = chips.filter(
        (chip) => !vm.isChipReadonly({ chip: chip })
      );

      vm.readonlyChips = chips.filter(
        (chip) => vm.isChipReadonly({ chip: chip })
      );

    }, true);

    scope.$watch('vm.chips', (newVal, oldVal) => {

      vm.filters = Object.assign({},
        $filter('chipsToFilters')(vm.readonlyChips),
        $filter('chipsToFilters')(newVal)
      );

    }, true);

  }

}

export default domainsSearch;
