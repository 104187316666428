function domainNotes() {

  return {
    restrict: 'E',
    templateUrl: 'home/domains/notes/tpl.html',
    scope: {},
    bindToController: {
      domain: '='
    },
    controller: controller,
    controllerAs: 'vm'
  };

  function controller(NotesFactory, DomainsFactory, $q, $stateParams, $timeout) {
    'ngInject';

    const vm = this;

    vm.$onInit = $onInit;
    vm.addNote = addNote;
    vm.editNote = editNote;
    vm.getNotes = getNotes;
    vm.deleteNote = deleteNote;
    vm.cancelEdit = cancelEdit;
    vm.toggleEditForm = toggleEditForm;
    vm.markAsContacted = markAsContacted;
    vm.contactedToggled = contactedToggled;
    vm.deleteNoteFromModel = deleteNoteFromModel;
    vm.deleteContactedNotes = deleteContactedNotes;

    function $onInit() {

      vm.editThis = [];
      vm.deletedNotes = [];

      // Init notes
      vm.getNotes();

    }

    function getNotes() {

      // Get notes
      NotesFactory.get(vm.domain.domain_name)
        .then((data) => {

          vm.notes = data;

          // Add edit flag to attach to each note
          vm.notes.map((note) => {
            note.editMe = false;
            return note;
          });

        });

    }

    // Find all notes referencing contacted and remove from view
    function deleteContactedNotes() {

      return $q.all(
        vm.notes.filter((note) => note.text === 'Shop was contacted')
          .reduce(
            // Submit request to delete each note,
            // return cumulative array of delete requests
            (deletePromises, note) => deletePromises.concat(
              [vm.deleteNote(note.id)]
            ),
            // Init reduce as empty array
            []
          )
      );

    }

    // Mark or unmark shop as contacted
    function contactedToggled() {

      return (
        vm.domain.contacted
        ? vm.markAsContacted()
        // TODO: Move this to API
        : vm.deleteContactedNotes()
      )
      // Allow some time for the model to update
      .then(() => $timeout(() => {}, 200))
      // Get new notes data
      .then(vm.getNotes);

    }

    // Mark as contacted, get notes again
    function markAsContacted() {

      return DomainsFactory.markAsContacted(vm.domain.domain_name);

    }

    // Add note, reset vm.newNote, get notes again
    function addNote(noteText) {

      NotesFactory.addNote(vm.domain.domain_name, noteText)
        .then((res) => {

          vm.newNote = null;

          vm.getNotes();

        });

    }

    // Edit note, then find the edited note and change the text (avoid api call)
    function editNote(note) {

      NotesFactory.editNote(note.id, note.text)
        .then((res) => note.editMe = false);

    }

    // Close edit, use cached text
    function cancelEdit(note) {

      note.editMe = false;
      note.text = note.cacheText;

    }

    // Open edit, cache text for cancel
    function toggleEditForm(note) {

      // Use cancel button to get rid of form
      if (note.editMe) {
        return;
      }

      note.editMe = true;
      note.cacheText = note.text;

    }

    // Delete note by ID
    function deleteNote(noteId) {

      NotesFactory.deleteNote(noteId)
        .then((res) => {

          vm.deleteNoteFromModel(noteId);

        });

    }

    // Return every note that doesn't match note id
    function deleteNoteFromModel(noteId) {

      // Add noteId to deleted notes list
      vm.deletedNotes.push(noteId);

      // If note isn't found in deleted note id list, return note
      vm.notes = vm.notes.filter((note) =>
        !vm.deletedNotes.find((nid) =>
          nid === note.id));

    }

  }

}

export default domainNotes;
