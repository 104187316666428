function domainActivity() {

  return {
    restrict: 'E',
    templateUrl: 'home/domains/activity/tpl.html',
    scope: {},
    bindToController: {
      domain: '='
    },
    controller: controller,
    controllerAs: 'vm'
  };

  function controller(DomainsFactory, $log, $stateParams) {
    'ngInject';

    const vm = this;

    vm.$onInit = $onInit;

    function $onInit() {

      vm.domainName = $stateParams.domainName;

      // Filter out non-accepted types
      DomainsFactory.getActivity(vm.domainName)
        .then((res) => {

          // Recursively flatten array
          // Filter out activity by type
          vm.domains_activity = res.length && res.reduce(
            (prev, curr) => prev.concat(curr)
          );

        })
        .catch((res) => res && res.data && $log.info(res.data.message));

    }

  }

}

export default domainActivity;
