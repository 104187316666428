function bulkDomainsOverlay() {

  return {
    restrict: 'EA',
    templateUrl: 'home/domains/search/bulkDomainsOverlay/tpl.html',
    scope: {},
    bindToController: {
      onClose: '&',
      domains: '<'
    },
    controller: controller,
    controllerAs: 'vm'
  };

  function controller($log, $filter, TagsFactory) {
    'ngInject';

    const vm = this;

    vm.$onChanges = $onChanges;
    vm.dismissModal = dismissModal;
    vm.setDomains = setDomains;

    function $onChanges(changes) {

      vm.domainsInput = changes.domains.currentValue;

    }

    function dismissModal(message) {

      return vm.onClose({message: message});

    }

    function setDomains(domainsInput) {

      vm.dismissModal({message: domainsInput.split(/[\s,]+/)});

    }

  }

}

export default bulkDomainsOverlay;
