import angular from 'angular';

// Routes
import '@uirouter/angularjs';
import homeRoutes from './routes';

// Directive
import lostSelectDomainCheckbox from './lostSelectDomainCheckbox/directive';
import lostToggleButtonGroup from './lostToggleButtonGroup/directive';
import graphRangeToggle from './graphRangeToggle/directive';
import listMapToggle from './listMapToggle/directive';
import lostMainNav from './lostMainNav/directive';
import breadcrumbs from './breadcrumbs/directive';
import mdChips from './mdChips/directive';

// Factory
import SelectedDomainsFactory from './lostSelectDomainCheckbox/factory';
import ProvidersFactory from './providers/factory';
import GoogleTagManagerFactory from './factories/googleTagManager.factory';
import JavaScriptFactory from './factories/javascript.factory';
import MapsFactory from './factories/maps.factory';

// Filter
import lowercaseStrip from './filters/lowercaseStrip/filter';
import numberAbbr from './filters/numberAbbr/filter';
import capitalize from './filters/capitalize/filter';
import nl2br from './filters/nl2br/filter';
import tel from './filters/tel/filter';

import './persistModel/';
import './locations/';
import './blacklist/';
import './domains/';
import './tags/';
import './map/';

const requires = [
  'ufn.lost.home.locations',
  'ufn.lost.home.blacklist',
  'ufn.lost.home.domains',
  'ufn.lost.home.tags',
  'ufn.lost.home.map',
  'ufn.lost.persist'
];

const homeModule = angular.module('ufn.lost.home', requires);

// Config
homeModule.config(homeRoutes);

// Directive
homeModule.directive('lostSelectDomainCheckbox', lostSelectDomainCheckbox);
homeModule.directive('lostToggleButtonGroup', lostToggleButtonGroup);
homeModule.directive('graphRangeToggle', graphRangeToggle);
homeModule.directive('listMapToggle', listMapToggle);
homeModule.directive('lostMainNav', lostMainNav);
homeModule.directive('breadcrumbs', breadcrumbs);
homeModule.directive('mdChips', mdChips);

// Filter
homeModule.filter('lowercaseStrip', lowercaseStrip);
homeModule.filter('numberAbbr', numberAbbr);
homeModule.filter('capitalize', capitalize);
homeModule.filter('nl2br', nl2br);
homeModule.filter('tel', tel);

// Factory
homeModule.factory('SelectedDomainsFactory', SelectedDomainsFactory);
homeModule.factory('ProvidersFactory', ProvidersFactory);
homeModule.factory('GoogleTagManagerFactory', GoogleTagManagerFactory);
homeModule.factory('JavaScriptFactory', JavaScriptFactory);
homeModule.factory('MapsFactory', MapsFactory);

export default homeModule;
