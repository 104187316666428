function locationsList() {

  return {
    restrict: 'E',
    scope: {},
    templateUrl: 'home/locations/locationsList/tpl.html',
    bindToController: {
      locations: '=',
      sort: '='
    },
    controller: controller,
    controllerAs: 'vm'
  };

  function controller() {
    'ngInject';

    const vm = this;

    vm.$onInit = $onInit;

    function $onInit() {

      vm.selected = [];

    }

  }

}

export default locationsList;
