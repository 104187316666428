function SelectedDomainsFactory($http, $q) {
  'ngInject';

  var selectedDomains = {};

  var service = {
    remove: remove,
    clear: clear,
    add: add,
    get: get,
    getDomainIds: getDomainIds,
    removeAll: removeAll,
    getObject: getObject
  };

  return service;

  function get() {

    return $q.when(selectedDomains);

  }

  function getObject() {

    return selectedDomains;

  }

  function remove(domainId) {

    delete selectedDomains[domainId];
    return $q.when(selectedDomains);

  }

  function clear() {

    selectedDomains = {};
    return $q.when(selectedDomains);

  }

  function add(domain) {

    // Find index of id
    selectedDomains[domain.id] = domain;
    return $q.when(selectedDomains);

  }

  function removeAll() {

    selectedDomains = {};

  }

  function getDomainIds() {

    return $q.when(Object.keys(selectedDomains));

  }

}

export default SelectedDomainsFactory;
