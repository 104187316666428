function domainsRoutes($stateProvider) {
  'ngInject';

  const urlParams = '?sort&sort_order&city&state&country&status&duplicates&clear&contacted&traffic&distance&text&provider&alerts&tag&name&content&https',
    domainsDetailsTemplate = '<domain-details flex="100" layout="column" layout-align="start stretch"></domain-details>';

  // Locations routes
  $stateProvider
    .state('home.domains', {
      url: 'domains' + urlParams,
      params: {
        tab: null,
        tag: null,
        text: null,
        sort: 'id',
        city: null,
        name: null,
        state: null,
        clear: null,
        alerts: null,
        country: null,
        traffic: null,
        status: null,
        distance: null,
        provider: null,
        content: 'list',
        contacted: null,
        https: null,
        duplicate: null,
        showFilters: null,
        sort_order: 'desc'
      },
      reloadOnSearch: true,
      templateUrl: 'home/domains/tpl.html'
    })
    .state('home.domains.details', {
      url: '/:domainName?tab',
      views: {
        'rightpanel@home.domains': {
          template: domainsDetailsTemplate
        }
      }
    })
    // 302s Routing: identical except for status
    .state('home.302s', {
      url: '302s' + urlParams,
      params: {
        tab: null,
        tag: null,
        text: null,
        sort: 'id',
        city: null,
        name: null,
        state: null,
        clear: null,
        alerts: null,
        country: null,
        traffic: null,
        status: '302',
        distance: null,
        provider: null,
        content: 'list',
        contacted: null,
        duplicate: null,
        showFilters: null,
        sort_order: 'desc',
        prospect: true
      },
      reloadOnSearch: true,
      templateUrl: 'home/domains/tpl.html'
    })
    .state('home.302s.details', {
      url: '/:domainName?tab',
      views: {
        'rightpanel@home.302s': {
          template: domainsDetailsTemplate
        }
      }
    });
}

export default domainsRoutes;
