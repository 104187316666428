function tagsAdd() {

  return {
    restrict: 'EA',
    templateUrl: 'home/tags/add/tpl.html',
    scope: {},
    bindToController: {
      onClose: '&',
      domains: '='
    },
    controller: controller,
    controllerAs: 'vm'
  };

  function controller($log, $filter, TagsFactory) {
    'ngInject';

    const vm = this;

    vm.$onInit = $onInit;
    vm.addChip = addChip;
    vm.addTags = addTags;
    vm.tagExists = tagExists;
    vm.addTextTag = addTextTag;
    vm.addDomains = addDomains;
    vm.dismissModal = dismissModal;
    vm.createAndAssignTag = createAndAssignTag;

    function $onInit() {

      vm.query = '';

      vm.addChipKeyCodes = [
        // enter
        13,
        // comma
        188
      ];

      vm.newTags = [];

      TagsFactory.getList()
        .then((res) => {

          var tagsArr = [];

          // Bring all tags together so they're not separated alphabetically
          angular.forEach(res, (val) => tagsArr.push(val));
          vm.tags = [].concat.apply([], tagsArr);

        });

    }

    // Convert text to chip and add
    function addTextTag(chip) {

      return $filter('filtersToChips')({
        text: chip
      })[0];

    }

    function tagExists(tag) {

      if (
        vm.newTags.find((t) => t.display_name === tag.display_name) ||
        // if domains exists, don't add to input if tag already exists in domain tags list
        (tag.display_name &&
          // No point in continuing if it's multiple domains,
          // since there will be no tags to look through
          // just return false
          vm.domains && vm.domains.length === 1 &&
          // Loop throught domains
          vm.domains[0].tags && vm.domains[0].tags.find(
            (dt) => dt.display_name === tag.display_name)
        )) {

        return true;

      } else {

        return false;

      }

    }

    // Add chip to search field unless already exists
    // within the newTags array or within the domain tags array
    function addChip(tag) {

      // Dont't add chip to input if already exists in new tags
      if (vm.tagExists(tag)) {

        return;

      }

      // Create display from display_name to show text in chip
      tag.display = tag.display_name;

      // Push to new tags so it won't be repeated in the md-chips input
      vm.newTags.push(tag);

    }

    // Close modal
    function dismissModal(message) {

      // $log.debug('closing', data);
      return vm.onClose({ message: message });

    }

    // Add tags to LOST and to Domain if domain is defined
    function addTags(tags) {

      // Make display_name
      tags.forEach((tag) => {

        tag.display_name = tag.display;

      });

      // For each tag, create and or assign
      vm.newTags.forEach((tag) => {

        vm.createAndAssignTag(tag);

      });

    }

    function createAndAssignTag(tag) {

      // If tag exists and domain exists, assign to domain
      if (vm.domains && tag.id) {

        return vm.addDomains(tag.id, vm.domains);

      }

      // If tag doesn't exist, create it and then
      // assign it to domain when ID comes back
      // OR if domain isn't present, dismiss modal
      TagsFactory.add(tag.display)
        .then(function (res) {

          // $log.debug(res);
          return vm.domains ? vm.addDomains(res, vm.domains) : vm.dismissModal(tag.display);

        });

    }

    function addDomains(tagId, domains) {

      var domainIds = domains.length === 1 && domains[0].id ? domains.map((domain) => domain.id) : domains;

      TagsFactory.addDomains(tagId, domainIds.map((id) => parseInt(id)))
        .then((res) => {

          // $log.debug('dismissing', tagId);
          vm.dismissModal(tagId);

        });

    };

  }

}

export default tagsAdd;
