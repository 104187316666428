function lostSelectDomainCheckbox() {

  return {
    restrict: 'E',
    scope: {},
    templateUrl: 'home/lostSelectDomainCheckbox/tpl.html',
    bindToController: {
      domain: '='
    },
    controller: controller,
    controllerAs: 'vm',
    link: link
  };

  function controller(SelectedDomainsFactory, $scope) {
    'ngInject';

    const vm = this;

    vm.selectDomain = selectDomain;
    vm.getSelectedDomains = getSelectedDomains;
    vm.$onInit = $onInit;

    function $onInit() {

      vm.isSelected = false;
      vm.getSelectedDomains();

    }

    function getSelectedDomains() {

      SelectedDomainsFactory.get()
        .then((res) => {

          vm.selectedDomains = res;
          vm.isSelected = vm.domain.id in vm.selectedDomains;

        });

    }

    function selectDomain(selected) {

      return selected ?
        SelectedDomainsFactory.add(vm.domain)
        .then((res) => vm.getSelectedDomains()) :
        SelectedDomainsFactory.remove(vm.domain.id)
        .then(() => vm.getSelectedDomains());

    }

  }

  function link(scope, elem) {
    'ngInject';

    const vm = scope.vm;

    scope.$watch('vm.selectedDomains', () => vm.getSelectedDomains(), true);

  }

}

export default lostSelectDomainCheckbox;
