function tagsList() {

  return {
    restrict: 'EA',
    scope: {},
    templateUrl: 'home/tags/list/tpl.html',
    bindToController: {
      tags: '=ngModel',
      edit: '='
    },
    controller: controller,
    controllerAs: 'vm'
  };

  function controller($log, $state, $stateParams, TagsFactory) {
    'ngInject';

    const vm = this;

    vm.deleteTag = deleteTag;
    vm.tagFilter = tagFilter;

    function deleteTag(chip) {

      TagsFactory.deleteTag(chip.id)
        .then((res) => {

          $log.debug(res);

        });

    }

    function tagFilter(tagName) {

      let tags = $stateParams.tag && $stateParams.tag.indexOf(tagName) === -1 ?
        $stateParams.tag += (',' + tagName) : tagName;

      $state.go($state.current, {
        tag: tags
      });

    }

  }

}

export default tagsList;
