function tagsSearchInput($filter) {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: 'home/tags/search/input/tpl.html',
    scope: {},
    bindToController: {},
    controller: controller,
    controllerAs: 'vm'
  };

  function controller() {
    'ngInject';

    const vm = this;

    vm.$onInit = $onInit;
    vm.addTextSearch = addTextSearch;
    vm.chips = [];

    function $onInit() {

      vm.addChipKeyCodes = [
        // enter
        13,
        // comma
        188
      ];

    }

    function addTextSearch(chip) {

      return $filter('filtersToChips')({
        text: chip
      })[0];

    }

  }

}

export default tagsSearchInput;
