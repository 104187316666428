import census  from 'citysdk';

function locationsDomainsDemographics() {
  'ngInject';

  return {
    restrict: 'E',
    scope: {},
    templateUrl: 'home/locations/locationsDomainsDemographics/tpl.html',
    bindToController: {
      location: '='
    },
    controller: controller,
    controllerAs: 'vm',
    link: link
  };

  function controller($scope, $stateParams, $log, $timeout, ENV, LocationsFactory) {
    'ngInject';

    const vm = this;

    vm.setUpMap = setUpMap;
    vm.getDemographics = getDemographics;

    function setUpMap(location) {

      // $log.debug('location', location, location.lat, location.lon);

      if (!location.lat || !location.lon) {
        return;
      }

      var map = new google.maps.Map(document.getElementById('demographicsMap'), {
        zoom: 11,
        center: {
          lat: location.lat,
          lng: location.lon
        },
        mapTypeControl: false,
        streetViewControl: false
      });

    }

    function getDemographics(location) {

      CitySdk.default.request({
          lat: location.lat,
          lng: location.lon,
          variables: ['population', 'income', 'age'],
          apikey: ENV.CENSUS_API_KEY,
          level: 'place',
          api: 'acs5',
          year: '2014'
        })
        .then((res) => {

          $timeout(() => {

            // $log.debug('demographics', res);

            var properties = res.features[0].properties;

            vm.demographics = Object.assign({}, {
              age: properties.B01002_001E,
              population: properties.B01003_001E,
              income: properties.B19013_001E,
              area: properties.AREALAND * 0.000001 * 0.386102
            });

            // $log.debug('Demographics', vm.demographics);

          }, 0);

        });

    }

  }

  function link(scope, elem) {
    'ngInject';

    const vm = scope.vm;

    scope.$watch('vm.location', (newValue, oldValue) => {

      if (!newValue) {
        return;
      }

      vm.setUpMap(newValue);
      vm.getDemographics(newValue);

    });

  }

}

export default locationsDomainsDemographics;
