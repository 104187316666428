function graphRangeToggle() {

    return {
        restrict: 'E',
        scope: {},
        templateUrl: 'home/graphRangeToggle/tpl.html',
        bindToController: {
            currentGraphRange: '=graphRange'
        },
        controller: controller,
        controllerAs: 'vm'
    };

    function controller() {
        'ngInject';

        const vm = this;

        vm.$onInit = $onInit;
        vm.setGraphRange = setGraphRange;

        function $onInit() {

            vm.graphRanges = [
                '1 Month',
                '6 Months',
                '1 Year',
                '2 Years'
            ];

            vm.defaultGraphRange = '2 Years';

            vm.setGraphRange(vm.currentGraphRange);

        }

        function setGraphRange(graphRange) {

            vm.currentGraphRange = vm.graphRanges
                // Find by value to ensure it's allowed
                .find(e => e === graphRange)
                // If unset, set current to default
                || vm.defaultGraphRange;

            return vm.currentGraphRange;

        }

    }

}

export default graphRangeToggle;
