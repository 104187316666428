import angular from 'angular';

// Routes
import '@uirouter/angularjs';
import domainsRoutes from './routes';

// Factories
import DomainsFactory from './factory';
import TagsFactory from '../tags/factory';
import NotesFactory from './notes/factory';

// Filters
import chipsToFilters from './search/filters/chipsToFilters';
import filtersToChips from './search/filters/filtersToChips';

// Directives
import domainInfo from './info/directive';
import domainNotes from './notes/directive';
import domainsSearch from './search/directive';
import domainActivity from './activity/directive';
import domainsList from './domainsList/directive';
import domainDetails from './domainDetails/directive';
import domainCompetitors from './competitors/directive';
import domainsContent from './domainsContent/directive';
import domainsOptions from './domainsOptions/directive';
import domainsSearchInput from './search/input/directive';
import domainsColumnToggle from './domainsColumnToggle/directive';
import domainsSearchFiltersForm from './search/filtersForm/directive';
import bulkDomainsOverlay from './search/bulkDomainsOverlay/directive';
import accountAddressTypeahead from '../accountAddressTypeahead/directive.js';

const domainsModule = angular.module('ufn.lost.home.domains', [
  'ui.router'
]);

domainsModule.config(domainsRoutes);

/*----------  Directives  ----------*/

domainsModule.directive('domainInfo', domainInfo);
domainsModule.directive('domainNotes', domainNotes);
domainsModule.directive('domainsList', domainsList);
domainsModule.directive('domainsSearch', domainsSearch);
domainsModule.directive('domainDetails', domainDetails);
domainsModule.directive('domainsContent', domainsContent);
domainsModule.directive('domainsOptions', domainsOptions);
domainsModule.directive('domainActivity', domainActivity);
domainsModule.directive('domainCompetitors', domainCompetitors);
domainsModule.directive('domainsSearchInput', domainsSearchInput);
domainsModule.directive('bulkDomainsOverlay', bulkDomainsOverlay);
domainsModule.directive('domainsColumnToggle', domainsColumnToggle);
domainsModule.directive('accountAddressTypeahead', accountAddressTypeahead);
domainsModule.directive('domainsSearchFiltersForm', domainsSearchFiltersForm);

/*----------  Filters  ----------*/

domainsModule.filter('chipsToFilters', chipsToFilters);
domainsModule.filter('filtersToChips', filtersToChips);

/*----------  Factories  ----------*/

domainsModule.factory('DomainsFactory', DomainsFactory);
domainsModule.factory('TagsFactory', TagsFactory);
domainsModule.factory('NotesFactory', NotesFactory);

export default domainsModule;
