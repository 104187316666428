function domainsSearchFiltersForm($filter) {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: 'home/domains/search/filtersForm/tpl.html',
    scope: {},
    bindToController: {
      toggledFilterChips: '=',
      filterOpts: '=',
      // Flag to show distance filter
      // Filter form should really be compiled from parent url,
      // but we're in too deep right now.
      showDistanceFilter: '='
    },
    controller: controller,
    controllerAs: 'vm',
    link: link
  };

  function controller() {
    'ngInject';

    const vm = this;

    vm.$onInit = $onInit;
    vm.setRadioFilter = setRadioFilter;

    function $onInit() {

      vm.statusOpts = [{
        type: 'status',
        display: 'OK (200)',
        value: '200'
      }, {
        type: 'status',
        display: 'Moved Permanently (301)',
        value: '301'
      }, {
        type: 'status',
        display: 'Found (Moved Temporarily) (302)',
        value: '302'
      }, {
        type: 'status',
        display: 'See Other (303)',
        value: '303'
      }, {
        type: 'status',
        display: 'Temporary Redirect (307)',
        value: '307'
      }, {
        type: 'status',
        display: 'Bad Request (400)',
        value: '400'
      }, {
        type: 'status',
        display: 'Unauthorized (401)',
        value: '401'
      }, {
        type: 'status',
        display: 'Forbidden (403)',
        value: '403'
      }, {
        type: 'status',
        display: 'Not Found (404)',
        value: '404'
      }, {
        type: 'status',
        display: 'Method Not Allowed (405)',
        value: '405'
      }, {
        type: 'status',
        display: 'Conflict (409)',
        value: '409'
      }, {
        type: 'status',
        display: 'Error (err)',
        value: 'err'
      }, {
        type: 'status',
        display: 'Network Connect (599)',
        value: '599'
      }];

      vm.alertsOpts = [{
        type: 'status',
        display: '302\'s',
        value: '302'
      }, {
        type: 'duplicates',
        display: 'Duplicates',
        value: 'true'
      }, {
        type: 'contacted',
        display: 'Contacted',
        value: '1'
      }, {
        type: 'contacted',
        display: 'Not Contacted',
        value: '0'
      }, {
        type: 'alert',
        display: 'No Provider',
        value: 'No Provider'
      }, {
        type: 'clear',
        display: 'Clear',
        value: 'true'
      }, {
        type: 'https',
        display: 'HTTPS',
        value: '1'
      }, {
        type: 'https',
        display: 'No HTTPS',
        value: '0'
      }];

      vm.trafficOpts = [{
        type: 'traffic',
        display: '> 10',
        value: 10
      }, {
        type: 'traffic',
        display: '> 50',
        value: 50
      }, {
        type: 'traffic',
        display: '> 100',
        value: 100
      }, {
        type: 'traffic',
        display: '> 200',
        value: 200
      }];

      vm.providersOpts = [{
        type: 'provider',
        display: 'Teleflora',
        value: 'Teleflora'
      }, {
        type: 'provider',
        display: 'FTD',
        value: 'FTD'
      }, {
        type: 'provider',
        display: 'Bloomnation',
        value: 'Bloomnation'
      }, {
        type: 'provider',
        display: 'FSN',
        value: 'FSN'
      }, {
        type: 'provider',
        display: 'Floranext',
        value: 'Floranext'
      }, {
        type: 'provider',
        display: 'Bloomnet',
        value: 'Bloomnet'
      }, {
        type: 'provider',
        display: 'Media99',
        value: 'Media99'
      }, {
        type: 'provider',
        display: 'Websystems',
        value: 'Websystems'
      }, {
        type: 'provider',
        display: 'Custom',
        value: 'Other'
      }, {
        type: 'provider',
        display: 'Domain for Sale',
        value: 'Domain for Sale'
      }, {
        type: 'provider',
        display: 'Epic Flowers',
        value: 'Epic Flowers'
      }, {
        type: 'provider',
        display: 'PFS',
        value: 'PFS'
      }, {
        type: 'provider',
        display: 'RFK',
        value: 'RFK'
      }, {
        type: 'provider',
        display: 'LAFS',
        value: 'LAFS'
      }, {
        type: 'provider',
        display: 'YPF',
        value: 'YPF'
      }, {
        type: 'provider',
        display: 'Yahoo Store',
        value: 'Yahoo Store'
      }, {
        type: 'provider',
        display: 'Flower Local',
        value: 'Flower Local'
      }, {
        type: 'provider',
        display: 'Squarespace',
        value: 'Squarespace'
      }, {
        type: 'provider',
        display: 'VistaPrint',
        value: 'VistaPrint'
      }, {
        type: 'provider',
        display: 'BigCommerce',
        value: 'BigCommerce'
      }, {
        type: 'provider',
        display: 'Volusion',
        value: 'Volusion'
      }, {
        type: 'provider',
        display: 'Shopify',
        value: 'Shopify'
      }, {
        type: 'provider',
        display: 'FloristBoard',
        value: 'FloristBoard'
      }, {
        type: 'provider',
        display: 'Webshop101',
        value: 'Webshop101'
      }, {
        type: 'provider',
        display: 'CanaFlora',
        value: 'CanaFlora'
      }, {
        type: 'provider',
        display: 'GravityFree',
        value: 'GravityFree'
      }, {
        type: 'provider',
        display: 'Lovingly',
        value: 'UFN'
      }, {
        type: 'provider',
        display: 'Unknown',
        value: 'Unknown'
      }, {
        type: 'provider',
        display: 'Other',
        value: 'Other'
      }];

      vm.distanceOpts = [{
        type: 'distance',
        display: '1 mile',
        value: 1
      }, {
        type: 'distance',
        display: '5 miles',
        value: 5
      }, {
        type: 'distance',
        display: '10 miles',
        value: 10
      }, {
        type: 'distance',
        display: '25 miles',
        value: 25
      }, {
        type: 'distance',
        display: '50 miles',
        value: 50
      }, {
        type: 'distance',
        display: '75 miles',
        value: 75
      }, {
        type: 'distance',
        display: '100 miles',
        value: 100
      }];

      vm.prospectOpts = [{
        type: 'tag',
        display: 'Prospect',
        value: 'Prospect'
      }];

    }

    function setRadioFilter(chip) {

      var tempArr = [];

      // Filter out chips of this type and add new one
      (
        tempArr = vm.toggledFilterChips.filter(
          (e) => e.type !== chip.type
        )
      )
      .push(chip);

      vm.toggledFilterChips = tempArr;

    }

  }

  function link(scope) {

    const vm = scope.vm;

    scope.$watch('vm.toggledFilterChips', (newVal) => {

      var traffic = newVal.find(
        (chip) => chip.type === 'traffic'
      );
      var distance = newVal.find(
        (chip) => chip.type === 'distance'
      );

      // Find the selected value from the options
      // necessary since filters will reformat without $$hashKey,
      // causing the model values not to match
      vm.traffic = traffic && vm.trafficOpts.find(
        (e) => e.value === traffic.value
      );
      vm.distance = distance && vm.distanceOpts.find(
        (e) => e.value === distance.value
      );

    }, true);

  }

}

export default domainsSearchFiltersForm;
