import tagsTestData from './tags.json';

function TagsFactory($http, $q, ENV) {
  'ngInject';

  var service = {
    getList: getList,
    get: get,
    edit: edit,
    deleteTag: deleteTag,
    add: add,
    addDomains: addDomains,
    deleteTagFromDomains: deleteTagFromDomains
  };

  return service;

  function getList() {

    return $http.get(ENV.API_BASE_URL + '/tags/')
      .then(function (res) {

        return $q.when(res.data);

      });

  }

  function get(domainId) {

    return $http.get(ENV.API_BASE_URL + '/tags/' + domainId)
      .then(function (res) {

        return $q.when(res.tags);

      });

  }

  function edit(domainId, tags) {

    return $http.put(ENV.API_BASE_URL + '/tags/' + domainId, tags)
      .then(function (res) {

        return $q.when(res);

      });

  }

  function deleteTag(tagId) {

    return $http.delete(ENV.API_BASE_URL + '/tags/' + tagId)
      .then(function (res) {

        return $q.when(res);

      });

  }

  function deleteTagFromDomains(tagId, domainIds) {

    var data = {
      'domain_ids': domainIds
    };

    return $http.delete(ENV.API_BASE_URL + '/tags/' + tagId + '/domains', {
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        data: data
      })
      .then(function (res) {

        return $q.when(res);

      });

  }

  function add(tag) {

    return $http.post(ENV.API_BASE_URL + '/tags/', { 'display_name': tag })
      .then(function (res) {

        return $q.when(res.data.tag_id);

      });

  }

  function addDomains(tagId, domainIds) {

    return $http.post(ENV.API_BASE_URL + '/tags/' + tagId + '/domains', { 'domain_ids': domainIds })
      .then(function (res) {

        return $q.when(res);

      });

  }

}

export default TagsFactory;
