function numberAbbr($filter) {
    'ngInject';

    return function(input) {

        let filtered;
        let reduced;

        if (input && !isNaN(input)) {

            if (input <= 999999) {

                filtered = $filter('number')(input);

            } else if (input <= 999999999) {

                reduced = input * .000001;
                filtered = Number(parseFloat(reduced).toFixed(3)) + ' M';

            } else if (input <= 999999999999) {

                reduced = input * .000000001;
                filtered = Number(parseFloat(reduced).toFixed(3)) + ' B';

            }

        }

        return filtered;

    };

}

export default numberAbbr;
