module.exports={
    "providers": [{
            "name": "Floranext",
            "active_domains": 9889,
            "traffic": 39847,
            "number_of_domains": 5000
        }, {
            "name": "Websystems",
            "active_domains": 684,
            "traffic": 39847,
            "number_of_domains": 5000
        }, {
            "name": "Media99",
            "active_domains": 9889,
            "traffic": 39847,
            "number_of_domains": 5000
        }, {
            "name": "LAFS",
            "active_domains": 63547,
            "traffic": 39847,
            "number_of_domains": 5000
        }, {
            "name": "RFK",
            "active_domains": 9889,
            "traffic": 39847,
            "number_of_domains": 5000
        }, {
            "name": "PFS",
            "active_domains": 9889,
            "traffic": 39847,
            "number_of_domains": 5000
        }, {
            "name": "FloristBoard",
            "active_domains": 9889,
            "traffic": 39847,
            "number_of_domains": 5000
        }, {
            "name": "EpicFlowers",
            "active_domains": 9889,
            "traffic": 39847,
            "number_of_domains": 5000
        }, {
            "name": "Bloomnation",
            "active_domains": 9889,
            "traffic": 39847,
            "number_of_domains": 5000
        }, {
            "name": "Custom?",
            "active_domains": 9889,
            "traffic": 39847,
            "number_of_domains": 5000
        }, {
            "name": "UFN",
            "active_domains": 9889,
            "traffic": 39847,
            "number_of_domains": 5000
        }, {
            "name": "Bloomnet",
            "active_domains": 9889,
            "traffic": 39847,
            "number_of_domains": 5000
        }, {
            "name": "FSN",
            "active_domains": 9889,
            "traffic": 39847,
            "number_of_domains": 5000
        }, {
            "name": "FTD",
            "active_domains": 9889,
            "traffic": 39847,
            "number_of_domains": 5000
        }, {
            "name": "Teleflora",
            "active_domains": 9889,
            "traffic": 39847,
            "number_of_domains": 5000
        }, {
            "name": "Vista Print",
            "active_domains": 9889,
            "traffic": 39847,
            "number_of_domains": 5000
        }, {
            "name": "Bigcommerce",
            "active_domains": 9889,
            "traffic": 39847,
            "number_of_domains": 15647
        }, {
            "name": "Cana Flora",
            "active_domains": 9889,
            "traffic": 39847,
            "number_of_domains": 5000
        }, {
            "name": "Domainify",
            "active_domains": 63547,
            "traffic": 39847,
            "number_of_domains": 15647
        }, {
            "name": "Volusion",
            "active_domains": 9889,
            "traffic": 39847,
            "number_of_domains": 5000
        }, {
            "name": "best-flowers.ca",
            "active_domains": 63547,
            "traffic": 39847,
            "number_of_domains": 156477
        }, {
            "name": "Sqarespace",
            "active_domains": 9889,
            "traffic": 39847,
            "number_of_domains": 156477
        }, {
            "name": "Webshop101",
            "active_domains": 9889,
            "traffic": 39847,
            "number_of_domains": 5000
        }, {
            "name": "Yahoo Store",
            "active_domains": 9889,
            "traffic": 39847,
            "number_of_domains": 5000
        }, {
            "name": "Beneva",
            "active_domains": 9889,
            "traffic": 39847,
            "number_of_domains": 5000
        },
    ]
};
