function domainsList() {

  return {
    restrict: 'E',
    scope: {},
    templateUrl: 'home/domains/domainsList/tpl.html',
    bindToController: {
      domains: '=ngModel',
      sort: '=',
      isLoading: '='
    },
    controller: controller,
    controllerAs: 'vm'
  };

  function controller($state, $stateParams, SelectedDomainsFactory) {
    'ngInject';

    const vm = this;

    vm.$onInit = $onInit;
    vm.selectAll = selectAll;

    function $onInit() {

      vm.allSelected = false;
      vm.$stateParams = $stateParams;
      vm.$state = $state;

    }

    function selectAll(val) {

      vm.domains.forEach((domain) => {

        if (val) {

          SelectedDomainsFactory.add(domain);

        } else {

          SelectedDomainsFactory.remove(domain.id);

        }

      });

    }

  }

}

export default domainsList;
