function mapContent($state, $stateParams) {
  'ngInject';

  return {
    restrict: 'E',
    scope: {},
    templateUrl: 'home/map/mapContent/tpl.html',
    bindToController: {},
    controller: controller,
    controllerAs: 'vm',
    link: link
  };

  function controller(DomainsFactory) {
    'ngInject';

    const vm = this;

    vm.$onInit = $onInit;
    vm.isChipReadonly = isChipReadonly;

    function isChipReadonly(chip) {

      return chip && (
        $state.is('home.302s') && (
          chip.type === 'status' && chip.value === '302'
        ) || $state.is('home.locations.list.domains') && (
          chip.type === 'location'
        )
      );

    }

    function $onInit() {

      vm.domains = [];

      vm.$stateParams = $stateParams;

      vm.filters = {
        city: $stateParams.city,
        state: $stateParams.state,
        country: $stateParams.country,
        // Coerce to integers to simplify filters
        traffic: ($stateParams.traffic && parseInt($stateParams.traffic)),
        distance: $stateParams.distance && parseInt($stateParams.distance),
        text: $stateParams.text,
        provider: $stateParams.provider,
        alerts: $stateParams.alerts,
        tag: $stateParams.tag,
        status: $stateParams.status,
        duplicates: $stateParams.duplicates,
        clear: $stateParams.clear,
        exclude_status: $stateParams.status && $stateParams.status.indexOf('599') !== -1 ? String(null) : 'null,599',
        mapview: true
      };

      // Only set up map when filters used
      if (vm.filters.city || vm.filters.state || vm.filters.country || vm.filters.traffic || vm.filters.distance || vm.filters.text || vm.filters.provider || vm.filters.alerts || vm.filters.tag || vm.filters.status || vm.filters.clear) {

        vm.isLoading = true;

        DomainsFactory.getList(vm.filters)
          .then((res) => {

            vm.domains = res.domains;

          })
          .finally(() => vm.isLoading = false);

      }

    }

  }

  function link(scope) {

    const vm = scope.vm;

    scope.$watch('vm.filters', (filters) => {

      // This prevents us from having to explicitly add
      // all non-filter parameters onto the search
      var deleteThese = ['traffic', 'distance', 'text', 'provider', 'alerts', 'tag', 'status', 'duplicates', 'clear'];

      deleteThese.forEach((param) => delete $stateParams[param]);

      angular.extend($stateParams, filters);

      $state.go($state.current.name, {});

    });
  }

}

export default mapContent;
