function blacklistOptions($document, $filter, $log, $state, $stateParams, $mdDialog, $mdToast, $q, ModalService, BlacklistFactory) {
  'ngInject';

  return {
    restrict: 'E',
    scope: {},
    templateUrl: 'home/blacklist/blacklistOptions/tpl.html',
    bindToController: {
      domains: '=ngModel',
      getDomains: '&',
      selected: '='
    },
    controller: controller,
    controllerAs: 'vm'
  };

  function controller($scope) {
    'ngInject';

    const vm = this;

    vm.openMenu = openMenu;
    vm.removeSelectedFromBlackList = removeSelectedFromBlackList;

    function openMenu($mdOpenMenu, ev) {

      $mdOpenMenu(ev);

    }

    function removeSelectedFromBlackList(ev) {

      if (!vm.selected.length) {
        $mdToast.showSimple('No selections have been made.');
      }

      // Appending dialog to document.body to cover sidenav in docs app
      var confirm = $mdDialog.confirm()
        .title('Are you sure you want to remove these domains from the blacklist?')
        .ariaLabel('Remove from blacklist')
        .targetEvent(ev)
        .ok('YES')
        .cancel('NO');

      $mdDialog.show(confirm).then(() =>
        BlacklistFactory.removeFromBlacklist(vm.selected)
        .then(() => {
          $mdToast.showSimple('Success');

          return vm.getDomains()
            .then((res) => vm.domains = res.blacklist);

        }),
        () => {

          $log.debug('No changes have been made.');

        });

    }

  }

}

export default blacklistOptions;
