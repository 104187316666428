function locationsRoutes($stateProvider) {
  'ngInject';

  // Locations routes
  $stateProvider
    .state('home.locations', {
      url: 'locations',
      templateUrl: 'home/locations/tpl.html'
    })
    .state('home.locations.list', {
      url: '/list?sort&sort_order',
      params: {
        sort: 'population',
        sort_order: 'desc'
      },
      views: {
        'content@home.locations': {
          template: '<locations-content flex="100" layout="column" layout-align="start stretch"></locations-content>'
        },
        'rightpanel@home.locations': {
          template: '<locations-right-panel flex="100" layout="column" layout-align="start stretch"></locations-right-panel>'
        }
      }
    })
    .state('home.locations.list.domains', {
      url: '/:city/:state?country&status&duplicates&clear&contacted&traffic&distance&text&provider&alerts&tag&name&locationId&lat&lng',
      params: {
        locationId: null,
        city: null,
        state: null,
        country: null,
        traffic: null,
        distance: null,
        text: null,
        provider: null,
        alerts: null,
        contacted: null,
        tag: null,
        status: null,
        duplicate: null,
        clear: null,
        name: null,
        lat: null,
        lng: null,
        content: 'list',
        sort: 'traffic',
        sort_order: 'desc'
      },
      reloadOnSearch: true,
      views: {
        'content@home.locations': {
          template: '<domains-content flex layout="column" layout-align="start stretch" show-distance-filter="true"></domains-content>'
        },
        'rightpanel@home.locations': {
          template: '<locations-domains-right-panel flex layout="column" layout-align="start stretch"></locations-domains-right-panel>'
        }
      }
    })
    .state('home.locations.list.domains.details', {
      url: '/:domainName?tab',
      views: {
        'rightpanel@home.locations': {
          template: '<domain-details flex="100" layout="column" layout-align="start stretch"></domain-details>'
        }
      }
    });

}

export default locationsRoutes;
