module.exports=[
  {
    "location": "Kansas City, MO, USA",
    "city": "Kansas City",
    "state": "Missouri",
    "country": "USA",
    "population": 6531100,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Kansas City, KS, USA",
    "city": "Kansas City",
    "state": "Kansas",
    "country": "USA",
    "population": 11250000,
    "score": null,
    "ad_spend": 99.3
  },
  {
    "location": "Louisville, USA",
    "city": null,
    "state": "OK Corral",
    "country": "USA",
    "population": 1500,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Alberta, CA",
    "city": "Alberta",
    "state": "Ontario",
    "country": "CA",
    "population": 6683512387,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Kansas City, MO, USA",
    "city": "Kansas City",
    "state": "Missouri",
    "country": "USA",
    "population": 6531100,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Kansas City, KS, USA",
    "city": "Kansas City",
    "state": "New York",
    "country": "USA",
    "population": 11250000,
    "score": null,
    "ad_spend": 99.3
  },
  {
    "location": "Louisville, USA",
    "city": null,
    "state": "Kentucky",
    "country": "USA",
    "population": 1500,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Alberta, CA",
    "city": "New York",
    "state": "Ontario",
    "country": "CA",
    "population": 6683512387,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Kansas City, MO, USA",
    "city": "Kansas City",
    "state": "Missouri",
    "country": "USA",
    "population": 6531100,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Kansas City, KS, USA",
    "city": "Kansas City",
    "state": "Kansas",
    "country": "USA",
    "population": 11250000,
    "score": null,
    "ad_spend": 99.3
  },
  {
    "location": "Louisville, USA",
    "city": null,
    "state": "Kentucky",
    "country": "USA",
    "population": 1500,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Alberta, CA",
    "city": "Alberta",
    "state": "Ontario",
    "country": "CA",
    "population": 6683512387,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Kansas City, MO, USA",
    "city": "Kansas City",
    "state": "Missouri",
    "country": "USA",
    "population": 6531100,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Kansas City, KS, USA",
    "city": "Kansas City",
    "state": "Kansas",
    "country": "USA",
    "population": 11250000,
    "score": null,
    "ad_spend": 99.3
  },
  {
    "location": "Louisville, USA",
    "city": null,
    "state": "Kentucky",
    "country": "USA",
    "population": 1500,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Alberta, CA",
    "city": "Alberta",
    "state": "Ontario",
    "country": "CA",
    "population": 6683512387,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Kansas City, MO, USA",
    "city": "Kansas City",
    "state": "Missouri",
    "country": "USA",
    "population": 6531100,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Kansas City, KS, USA",
    "city": "Kansas City",
    "state": "Kansas",
    "country": "USA",
    "population": 11250000,
    "score": null,
    "ad_spend": 99.3
  },
  {
    "location": "Louisville, USA",
    "city": null,
    "state": "Kentucky",
    "country": "USA",
    "population": 1500,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Alberta, CA",
    "city": "Alberta",
    "state": "Ontario",
    "country": "CA",
    "population": 6683512387,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Kansas City, MO, USA",
    "city": "Kansas City",
    "state": "Missouri",
    "country": "USA",
    "population": 6531100,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Kansas City, KS, USA",
    "city": "Kansas City",
    "state": "Kansas",
    "country": "USA",
    "population": 11250000,
    "score": null,
    "ad_spend": 99.3
  },
  {
    "location": "Louisville, USA",
    "city": null,
    "state": "Kentucky",
    "country": "USA",
    "population": 1500,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Alberta, CA",
    "city": "Alberta",
    "state": "Ontario",
    "country": "CA",
    "population": 6683512387,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Kansas City, MO, USA",
    "city": "Kansas City",
    "state": "Missouri",
    "country": "USA",
    "population": 6531100,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Kansas City, KS, USA",
    "city": "Kansas City",
    "state": "Kansas",
    "country": "USA",
    "population": 11250000,
    "score": null,
    "ad_spend": 99.3
  },
  {
    "location": "Louisville, USA",
    "city": null,
    "state": "New York",
    "country": "USA",
    "population": 1500,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Alberta, CA",
    "city": "Alberta",
    "state": "Ontario",
    "country": "CA",
    "population": 6683512387,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Kansas City, MO, USA",
    "city": "Kansas City",
    "state": "Missouri",
    "country": "USA",
    "population": 6531100,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Kansas City, KS, USA",
    "city": "Kansas City",
    "state": "Kansas",
    "country": "USA",
    "population": 11250000,
    "score": null,
    "ad_spend": 99.3
  },
  {
    "location": "Louisville, USA",
    "city": null,
    "state": "Kentucky",
    "country": "USA",
    "population": 1500,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Alberta, CA",
    "city": "Omaha",
    "state": "Ontario",
    "country": "CA",
    "population": 6683512387,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Kansas City, MO, USA",
    "city": "Kansas City",
    "state": "Missouri",
    "country": "USA",
    "population": 6531100,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Kansas City, KS, USA",
    "city": "Kansas City",
    "state": "Omaha",
    "country": "USA",
    "population": 11250000,
    "score": null,
    "ad_spend": 99.3
  },
  {
    "location": "Louisville, USA",
    "city": null,
    "state": "New York",
    "country": "USA",
    "population": 1500,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Alberta, CA",
    "city": "Alberta",
    "state": "Ontario",
    "country": "CA",
    "population": 6683512387,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Kansas City, MO, USA",
    "city": "Kansas City",
    "state": "Missouri",
    "country": "USA",
    "population": 6531100,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Kansas City, KS, USA",
    "city": "Kansas City",
    "state": "Kansas",
    "country": "USA",
    "population": 11250000,
    "score": null,
    "ad_spend": 99.3
  },
  {
    "location": "Louisville, USA",
    "city": null,
    "state": "Kentucky",
    "country": "USA",
    "population": 1500,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Alberta, CA",
    "city": "Alberta",
    "state": "Ontario",
    "country": "CA",
    "population": 6683512387,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Kansas City, MO, USA",
    "city": "Kansas City",
    "state": "Missouri",
    "country": "USA",
    "population": 6531100,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Kansas City, KS, USA",
    "city": "Kansas City",
    "state": "Kansas",
    "country": "USA",
    "population": 11250000,
    "score": null,
    "ad_spend": 99.3
  },
  {
    "location": "Louisville, USA",
    "city": null,
    "state": "Kentucky",
    "country": "USA",
    "population": 1500,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Alberta, CA",
    "city": "Alberta",
    "state": "Ontario",
    "country": "CA",
    "population": 6683512387,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Kansas City, MO, USA",
    "city": "Kansas City",
    "state": "Missouri",
    "country": "USA",
    "population": 6531100,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Kansas City, KS, USA",
    "city": "Kansas City",
    "state": "Kansas",
    "country": "USA",
    "population": 11250000,
    "score": null,
    "ad_spend": 99.3
  },
  {
    "location": "Louisville, USA",
    "city": null,
    "state": "Nebraska",
    "country": "USA",
    "population": 1500,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Alberta, CA",
    "city": "Alberta",
    "state": "Ontario",
    "country": "CA",
    "population": 6683512387,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Kansas City, MO, USA",
    "city": "Kansas City",
    "state": "Missouri",
    "country": "USA",
    "population": 6531100,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Kansas City, KS, USA",
    "city": "Omaha",
    "state": "Kansas",
    "country": "USA",
    "population": 11250000,
    "score": null,
    "ad_spend": 99.3
  },
  {
    "location": "Louisville, USA",
    "city": null,
    "state": "Nebraska",
    "country": "USA",
    "population": 1500,
    "score": 10,
    "ad_spend": 99.3
  },
  {
    "location": "Alberta, CA",
    "city": "Alberta",
    "state": "Ontario",
    "country": "CA",
    "population": 6683512387,
    "score": 10,
    "ad_spend": 99.3
  }
]
