function lostMainNav() {

  return {
    restrict: 'E',
    scope: {},
    templateUrl: 'home/lostMainNav/tpl.html',
    bindToController: {},
    controller: controller,
    controllerAs: 'vm'
  };

  function controller() {
    'ngInject';

    const vm = this;

    vm.navOpts = [
    // {
    //   name: 'Dashboard',
    //   target: 'home.dashboard',
    //   icon: 'dashboard'
    // },
    {
      name: 'Location',
      target: 'home.locations.list',
      icon: 'room'
    },
    {
      name: 'Domains',
      target: 'home.domains',
      icon: 'web'
    },
    // {
    //   name: 'Providers',
    //   target: 'home.providers',
    //   icon: 'supervisor_account'
    // },
    {
      name: 'Map',
      target: 'home.map',
      icon: 'map'
    },
    {
      name: 'Hot Leads',
      target: 'home.302s',
      icon: 'warning'
    },
    {
      name: 'Blacklist',
      target: 'home.blacklist',
      icon: 'not_interested'
    },
    // {
    //   name: 'GPP\'s',
    //   target: 'home.gpp',
    //   icon: 'account_box'
    // },
    // {
    //   name: 'Activity',
    //   target: 'home.activity',
    //   icon: 'timeline'
    // },
    {
      name: 'Tags',
      target: 'home.tags',
      icon: 'bookmark'
    }];

  }

}

export default lostMainNav;
