function chipsToFilters($filter) {
  'ngInject';

  return chipsToFiltersFilter;

  function chipsToFiltersFilter(chips) {

    var filters = {};

    // Allow only one of this type
    var location = $filter('filter')(
      chips, { type: 'location' }
    ) && $filter('filter')(
      chips, { type: 'location' }
    )[0];

    if (location) {

      // location's value will be an object,
      // each property of which is its own filter param
      angular.extend(filters, location.value);

    }

    // Bulk Domains
    // filter is preformatted
    filters.name = (
      chips.find((c) => c.type === 'name') || {}
    ).value;

    // Allow only one of this type
    var distance = $filter('filter')(
      chips, { type: 'distance' }
    )[0];
    if (distance) {

      filters.distance = distance.value;

    }

    // Allow only one of this type
    var traffic = $filter('filter')(
      chips, { type: 'traffic' }
    )[0];
    if (traffic) {

      filters.traffic = traffic.value;

    }

    // Get all tags chips
    var tags = $filter('filter')(chips, { type: 'tag' });
    if (tags && tags.length) {

      filters.tag = joinChipValues(tags);

    }

    // Extract values from chips and join
    var alerts = $filter('filter')(chips, { type: 'alert' });
    if (alerts && alerts.length) {

      filters.alerts = joinChipValues(alerts);

    }

    // Extract values from chips and join
    var contacted = $filter('filter')(chips, { type: 'contacted' })[0];
    if (contacted) {

      filters.contacted = contacted.value;

    }

    // Extract values from chips and join
    var https = $filter('filter')(chips, { type: 'https' })[0];
    if (https) {

      filters.https = https.value;

    }
    
    // Extract values from chips and join
    var status = $filter('filter')(chips, { type: 'status' });
    if (status && status.length) {

      filters.status = joinChipValues(status);

    }

    // Extract values from chips and join
    var duplicates = $filter('filter')(chips, { type: 'duplicates' });
    if (duplicates && duplicates.length) {

      filters.duplicates = joinChipValues(duplicates);

    }

    // Extract values from chips and join
    var clear = $filter('filter')(chips, { type: 'clear' });
    if (clear && clear.length) {

      filters.clear = joinChipValues(clear);

    }

    // Extract values from chips and join
    var provider = $filter('filter')(chips, { type: 'provider' });
    if (provider && provider.length) {

      filters.provider = joinChipValues(provider);

    }

    // Extract values from chips and join
    var text = $filter('filter')(chips, { type: 'text' });
    if (text && text.length) {

      filters.text = joinChipValues(text);

    }

    return filters;

  }

  function joinChipValues(arr) {

    return arr.map(
      (c) => c.value
    ).join(',');

  }

}

export default chipsToFilters;
