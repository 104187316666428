function locationsSearch(ENV) {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: 'home/locations/locationsSearch/tpl.html',
    scope: {},
    bindToController: {
      onSelectLocation: '&'
    },
    controller: controller,
    controllerAs: 'vm'
  };

  function controller($q, LocationsFactory) {
    'ngInject';

    const vm = this;

    /* METHODS */
    vm.queryLocation = queryLocation;
    vm.setLocation = setLocation;

    function queryLocation(query) {

      var state;
      // Get the city, optionally a two-char state code
      // in "city, st" format. Ignore ", " without a state.
      var city = query.replace(/^(.*?)(?:(?:\,)\s*([A-Za-z]{2})?)?$/, (m, $1, $2) => {

        state = $2;

        return $1;

      });

      return LocationsFactory.get({
        text: city,
        state: state
      })
      .then((res) => {

        return $q.when(res.locations);

      });

    }

    function setLocation(location) {

      // No location match shouldn't set
      if (!location) {
        return;
      }

      return vm.onSelectLocation({
        location: location
      });

    }

  }

}

export default locationsSearch;
