angular.module("ufn.lost.env", [])

.constant("ENV", {
	"API_BASE_URL": "https://lostapi.ufn.com",
	"AUTH0_DOMAIN": "ufn.auth0.com",
	"AUTH0_CLIENT_ID": "PFNkQeR5MpMSoDc37fPXLnFoGwPxuLnS",
	"CENSUS_API_KEY": "5058ec838d50aa3fe9dca28c6756dacb1f723cb2",
	"GOOGLE": {
		"MAPS_JAVASCRIPT_API_PROJECT": "production-lv-pearl",
		"MAPS_JAVASCRIPT_API_KEY": "AIzaSyBMqc8vaVrHxYeU0rXd7d6gr_0FfeFV6uI",
		"TAG_MANAGER_CONTAINER_ID": "GTM-5JM7SL2"
	}
})

;