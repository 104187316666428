import moment from 'moment';

function domainInfo($document, $log) {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: 'home/domains/info/tpl.html',
    scope: {},
    bindToController: {
      domain: '='
    },
    controller: controller,
    controllerAs: 'vm',
    link: link
  };

  function controller(DomainsFactory, TagsFactory, ModalService, $stateParams, ENV) {
    'ngInject';

    const vm = this;

    vm.$onInit = $onInit;
    vm.deleteTag = deleteTag;
    vm.setUpMap = setUpMap;
    vm.getDomain = getDomain;
    vm.updateTraffic = updateTraffic;
    vm.writeAddress = writeAddress;

    function $onInit() {

      var data, x;

      vm.localIcons = {
        default_icon: {
          iconUrl: 'https://res.cloudinary.com/ufn/image/upload/v1461092519/pos_assets/Map_Point.png',
          iconSize: [22, 33]
        }
      };

      vm.showMap = true;

      vm.getDomain(true);

      DomainsFactory.getTraffic($stateParams.domainName)
        .then(function (res) {

          data = res.history;

          if (res.current) {
            x = res.current;
            x.date = (new Date()).toISOString();
            data.push(x);
          }

          vm.domainTrafficChart = getDomainTrafficChart(data);

        });

    }

    function writeAddress(address) {

      vm.isEditAddress = false;

      if (vm.domain.addresses &&
        vm.domain.addresses[0] &&
        vm.domain.addresses[0].id) {
        address.id = vm.domain.addresses[0].id;
      }

      DomainsFactory.updateAddress({
          domain_name: vm.domain.domain_name,
          contact: address
        })
        .then(() => vm.getDomain(true));

    }

    function getDomain(setMap) {

      DomainsFactory.get($stateParams.domainName)
        .then((res) => {

          vm.domain = res;

          if (!vm.domain || !vm.domain.addresses || !vm.domain.addresses.length) {
            return;
          }

          vm.addressConcat = [
              vm.domain.addresses[0].city,
              vm.domain.addresses[0].state,
              vm.domain.addresses[0].postal
            ]
            // Remove undefined items
            .filter((a) => angular.isDefined(a))
            .join(', ');

          return (vm.domain.addresses[0].lat && vm.domain.addresses[0].lon) ?
            vm.setUpMap(vm.domain.addresses[0].lon, vm.domain.addresses[0].lat) : '';

        });

    }

    function deleteTag(tagId) {

      // $log.debug([vm.domain.id]);

      TagsFactory.deleteTagFromDomains(tagId, [vm.domain.id])
        .then((res) => vm.getDomain());

    }

    function updateTraffic(domainName) {

      DomainsFactory.updateTraffic(domainName)
        .then((res) => vm.getDomain());

    }

    function setUpMap(lon, lat) {

      if (!lon || !lat) {

        vm.showMap = false;

        return;

      }

      let loc = { lat: lat, lng: lon };

      let map = new google.maps.Map(document.getElementById('locationMap'), {
        zoom: 16,
        center: loc,
        mapTypeControl: false,
        streetViewControl: false,
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_TOP
        }
      });

      var marker = new google.maps.Marker({
        position: loc,
        map: map,
        icon: {
          url: vm.localIcons.default_icon.iconUrl,
          size: new google.maps.Size(vm.localIcons.default_icon.iconSize[0],
            vm.localIcons.default_icon.iconSize[1])
        }
      });

    }

    function getDomainTrafficChart(data) {

      let chartOpts = {
        legend: {
          position: 'bottom',
          alignment: 'end'
        },
        isStacked: true,
        displayExactValues: true,
        textStyle: {
          color: '#666',
          fontName: 'Roboto, "Helvetica Neue", sans-serif',
          fontSize: '12px'
        }
      };

      let chartData = {
        cols: [{
          id: 'month',
          label: 'Month',
          type: 'string'
        }, {
          id: 'paid',
          label: 'Paid Traffic',
          type: 'number'
        }, {
          id: 'organic',
          label: 'Organic Traffic',
          type: 'number'
        }],
        // Fill in each row with the date, adwords traffic an organic traffic #s
        rows: data.map((month) => {
          return {
            c: [{
              v: moment(month.date).format("MMM 'YY")
            }, {
              v: month.adwords
            }, {
              v: month.organic
            }]
          };
        })
      };

      var chart = {
        type: 'LineChart',
        data: chartData,
        options: chartOpts
      };

      return chart;

    }

  }

  function link(scope, elem) {
    'ngInject';

    const vm = scope.vm;

    // scope.$when('vm.domain.addresses', (newValue, oldValue) => {

    //   vm.isEditAddress = newValue.isEditAddress;
    //   vm.editAddress = newVal;

    // }, true);

  }

}

export default domainInfo;
