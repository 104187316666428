function domainsColumnToggle() {
  'ngInject';

  return {
    restrict: 'E',
    scope: {},
    templateUrl: 'home/domains/domainsColumnToggle/tpl.html',
    bindToController: {
      showRowsClass: '='
    },
    controller: controller,
    controllerAs: 'vm',
    link: link
  };

  function controller($scope, PersistModelFactory) {
    'ngInject';

    const vm = this;

    vm.$onInit = $onInit;
    vm.visibleRows = visibleRows;
    vm.initColumnToggles = initColumnToggles;

    function $onInit() {

      // If a model was persisted between state loads, use it
      const persistedModel = PersistModelFactory.getPersistedModel('vm.toggledFilterChips');

      vm.columnToggles = vm.initColumnToggles();

      // Assign toggles
      vm.toggledFilterChips = persistedModel ? PersistModelFactory.getPersistedModel('vm.toggledFilterChips')
        // init default toggles
        : vm.initColumnToggles();

      vm.visibleRows();

    }

    function initColumnToggles() {

      return [{
        display: 'Address',
        type: 'address',
        value: 1
      }, {
        display: 'Phone',
        type: 'phone',
        value: 1
      }, {
        display: 'Status',
        type: 'status',
        value: 1
      }, {
        display: 'Clear',
        type: 'clear',
        value: 1
      }, {
        display: 'SE Traffic',
        type: 'traffic',
        value: 1
      }, {
        display: 'Score',
        type: 'score',
        value: 1
      }, {
        display: 'Ad spend',
        type: 'ad_spend',
        value: 1
      }, {
        display: 'HTTPS',
        type: 'https',
        value: 1
      }];

    }

    function visibleRows() {
 
      if (!vm.toggledFilterChips) {
        return true;
      }

      vm.showRowsClass = (vm.toggledFilterChips &&
        vm.toggledFilterChips.map((toggle) =>
          toggle.type).join(' ')) || '';

    }

  }

  function link(scope) {

    const vm = scope.vm;

    scope.$watch('vm.toggledFilterChips', (newVal) => {

      vm.visibleRows();

    }, true);

  }

}

export default domainsColumnToggle;
