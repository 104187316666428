function domainsSearchInput($filter) {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: 'home/domains/search/input/tpl.html',
    scope: {},
    bindToController: {
      chips: '=toggledFilterChips',
      readonlyChips: '='
    },
    controller: controller,
    controllerAs: 'vm'
  };

  function controller($state, $stateParams) {
    'ngInject';

    const vm = this;

    vm.$onInit = $onInit;
    vm.addTextSearch = addTextSearch;

    function $onInit() {

      vm.isReadonly = false;

      vm.addChipKeyCodes = [
        // enter
        13,
        // comma
        188
      ];

    }

    function addTextSearch(chip) {

      return $filter('filtersToChips')({
        text: chip
      })[0];

    }

  }

}

export default domainsSearchInput;
