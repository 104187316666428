function addTagIcon() {
  'ngInject';

  return {
    restrict: 'EA',
    templateUrl: 'home/tags/addTagIcon/tpl.html',
    scope: {},
    bindToController: {
      domain: '=',
      onClose: '&'
    },
    controller: controller,
    controllerAs: 'vm',
    link: link
  };

  function link(scope, element) {
    'ngInject';

    const vm = scope.vm;

    element.bind('click', vm.showTagsModal);

  }

  function controller($document, $log, $stateParams, TagsFactory, DomainsFactory, ModalService, ENV) {
    'ngInject';

    const vm = this;

    vm.$onInit = $onInit;
    // vm.getDomain = getDomain;
    vm.showTagsModal = showTagsModal;

    function $onInit() {

      // init flag so modal doesn't get triggered twice
      vm.modalOpen = false;

    }

    // Get domains from stateparams
    // function getDomain() {

    //   DomainsFactory.get($stateParams.domainName)
    //     .then(function (res) {

    //       vm.domain = res;

    //     });

    // }

    function showTagsModal() {

      if (vm.modalOpen) {
        return;
      }

      return ModalService.showModal({
        controller: function (close) {
          'ngInject';

          // Set flag to true to stop repeated opening
          // TODO: Find out why this is happening
          vm.modalOpen = true;

          // Controller methods
          this.dismissModal = dismissModal;
          this.domains = vm.domain && [vm.domain];

          // The close method
          function dismissModal(data) {

            close(data);

          };

          // Close on escape
          $document.on('keyup', (e) => {

            if (e.keyCode === 27) { dismissModal(); }

          });

        },
        controllerAs: 'vm',
        template: '<tags-add class="overlay tags-add" domains="vm.domains" on-close="vm.dismissModal(message)"></tags-add>'
      }).then((modal) => {

        modal.close.then((value) => {

          $log.debug('value', value);

          // Reset the flag so we can open it again
          vm.modalOpen = false;

          $log.debug('Tag added: ' + value);

          return vm.onClose();

        });

      });

    }

  }

}

export default addTagIcon;
