function breadcrumbs() {

  return {
    restrict: 'E',
    scope: {},
    templateUrl: 'home/breadcrumbs/tpl.html',
    bindToController: {},
    controller: controller,
    controllerAs: 'vm'
  };

  function controller($scope, $state, $stateParams) {
    'ngInject';

    const vm = this;

    $scope.$watchCollection(() => {

      return $state.params;

    }, (newVal) => {

      var stateNames = {
        // Map
        'home.map': 'Map',
        'home.map.details': 'Map > ' + $stateParams.domainName,
        // Hot Leads
        'home.302s': 'Hot Leads',
        'home.302s.details': 'Hot Leads > ' + $stateParams.domainName,
        // Tags
        'home.tags': 'Tags',
        'home.tags.details': 'Tags > ' + $stateParams.domainName,
        // Domains
        'home.domains': 'Domains',
        'home.domains.details': 'Domains > ' + $stateParams.domainName,
        // Blacklist
        'home.blacklist': 'Blacklist',
        // Locations
        'home.locations.list': 'Locations',
        'home.locations.list.domains': 'Locations > Domains',
        'home.locations.list.domains.details': 'Locations > Domains > ' + $stateParams.domainName,
      };

      vm.stateDisplayName = stateNames[$state.current.name];
      
    }, true);

  }

}

export default breadcrumbs;
