module.exports={
    "activity": [{
        "type": "note",
        "title": "A Note was made on this domain",
        "content": "Contact domain owner. Have phone meeting next week.",
        "created": "2015-08-12T18:37:12+00:00"
    }, {
        "type": "alert",
        "title": "Alert! 302",
        "content": "",
        "created": "2015-08-12T18:37:12+00:00"
    }, {
        "type": "note",
        "title": "A Note was made on this domain",
        "content": "Contact domain owner. Was instructed to call at a later date.",
        "created": "2015-08-12T18:37:12+00:00"
    }, {
        "type": "provider",
        "title": "Teleflora became a domain provider",
        "content": "",
        "created": "2015-08-12T18:37:12+00:00"
    }, {
        "type": "domain_purchased",
        "title": "Domain was purchased",
        "content": "",
        "created": "2015-08-12T18:37:12+00:00"
    }, ]
}
